import {createSlice} from "@reduxjs/toolkit";
import toast from "react-hot-toast";

import dayjs from "dayjs";
import {factory} from "../../api/apiFactory";

const hotelsApi = factory.get("hotels");

const initialState = {
    filters: {
        srk: "",
        city: null,
        check_in: "2023-06-10",
        check_out: "2023-06-18",
        adult: 1,
        nationality: null,
        children: 0,
        classification: null,
        name: null,
    },
    results: {
        loading: false,
        status: false,
        message: "",
        description: "",
        data: {
            status: "",
            progress: "",
            countHotels: 0,
            countOffers: 0,
            hotels: [],
            srk: "",
            tokens: {
                async: "",
                progress: "",
                results: "",
            },
        },
    },
    hotel: {
        dialog: false,
        hotel_id: null,
        loading: false,
        status: false,
        message: "",
        description: "",
        data: {
            main_info: {
                data: "",
                loading: false,
            },
            description: {
                data: "",
                loading: false,
            },
            main_images: {
                data: "",
                loading: false,
            },
            hotel_images: {
                data: [],
                loading: false,
            },
            offers: {
                data: [],
                loading: false,
            },
            room: {
                offer_id: "",
                packageToken: "",
                roomTokens: "",
                main: "",
                more_details: "",
                loading: false,
            },
            pre_booking: {
                data: {
                    availabilityToken: "",
                    serviceDates: {},
                    package: {
                        packageCode: "",
                        packageToken: "",
                        status: "",
                        complete: "",
                        price: {},
                        rate: {},
                        packageRooms: [],
                    },
                    rooms: [],
                    paymentMethods: [],
                    bookingOptions: [],
                    autocancelDate: "",
                    cancellationPolicy: {},
                    priceBreakdown: {},
                    remarks: [],
                    taxes: [],
                    offerConfiguration: {},
                },
                loading: false,
            },
            book: {
                data: {},
                loading: false,
                form: "",
            },
        },
    },
    errors: "",
};

export const hotelsSlice = createSlice({
    name: "hotels",
    initialState,
    reducers: {
        setResults(state, {payload}) {
            state.results = {...payload};
            state.results.loading = false;
        },
        setHotelsFilter(state, {payload}) {
            state.filters = {...payload};
        },
        setHotelErrors(state, {payload}) {
            state.errors = payload;
        },
        setHotel(state, {payload}) {
            Object.keys(payload).forEach((key) => {
                if (key === "offers") {
                    state.hotel.data[key].data = payload[key].data.offers;
                } else {
                    state.hotel.data[key].data = payload[key].data;
                }
                state.hotel.data[key].loading = false;
            });
        },
        setSelectedRoom(state, {payload}) {
            if (payload.offer_id) {
                state.hotel.data.room.offer_id = payload.offer_id;
                state.hotel.data.room.more_details = payload.more_details;
                state.hotel.data.room.main = payload.main;
                state.hotel.data.room.packageToken = payload.packageToken;
                state.hotel.data.room.roomTokens = payload.roomTokens;
            } else {
                state.hotel.data.room = initialState.hotel.data.room;
            }

            state.hotel.data.room.loading = false;
        },
        clearHotel(state) {
            state.hotel = initialState.hotel;
        },
        setLoading: (state, {payload}) => {
            state[payload].loading = !state[payload].loading;
        },
        setLoadingHotel: (state, {payload}) => {
            state.hotel.data[payload].loading = !state.hotel.data[payload].loading;
        },
        setDialog: (state, {payload}) => {
            if (payload) state.hotel.hotel_id = payload;
            state.hotel.dialog = !state.hotel.dialog;
        },
        setPreBooking: (state, {payload}) => {
            state.hotel.data.pre_booking.data = {
                ...state.hotel.data.pre_booking.data,
                ...payload.data,
            };
            state.hotel.data.pre_booking.loading = false;
        },
        setBook: (state, {payload}) => {
            state.hotel.data.book.data = {
                ...state.hotel.data.book.data,
                ...payload.data,
            };
            state.hotel.data.book.loading = false;
        },
    },
});

export const {
    setLoading,
    setLoadingHotel,
    setResults,
    setHotel,
    setSelectedRoom,
    setPreBooking,
    clearHotel,
    setDialog,
    setHotelErrors,
    setHotelsFilter,
} = hotelsSlice.actions;

export default hotelsSlice.reducer;

//axios
const getSearchData = (filters) => async (dispatch, getState) => {
    try {
        console.log("filters: ", filters)
        const data_filters = {
            srk: filters.srk,
            city: filters?.city?.id,
            country: filters?.country?.id,
            check_in: dayjs(filters?.check_in).format("YYYY-MM-DD"),
            check_out: dayjs(filters?.check_out).format("YYYY-MM-DD"),
            nationality: filters?.nationality?.id,
            adult: filters?.adult,
            children: filters.children?.length > 0 ? filters?.children : null,
            classification:
                filters.classification?.length > 0 ? filters?.classification : null,
            name: filters?.name,
        };
        dispatch(setHotelsFilter(data_filters));

        dispatch(setLoading("results"));
        const res = await hotelsApi.search(data_filters);
        dispatch(setResults(res.data));

        // set srk from results
        dispatch(setHotelsFilter({...data_filters, srk: res.data.data.srk}));
    } catch (err) {
        console.log(err);
        toast.error(err.response.data.message);
        dispatch(setLoading("results"));
        throw new Error(err);
    }
};
const getByID = (id) => async (dispatch, getState) => {
    try {
        dispatch(clearHotel());
        dispatch(setLoadingHotel("main_info"));
        const mainInfo = await hotelsApi.mainInfo(id);
        dispatch(
            setHotel({
                main_info: mainInfo.data,
            })
        );
        dispatch(setLoadingHotel("description"));
        const description = await hotelsApi.description(id);
        dispatch(
            setHotel({
                description: description.data,
            })
        );
        dispatch(setLoadingHotel("main_images"));
        const mainImages = await hotelsApi.mainImages(id);
        dispatch(
            setHotel({
                main_images: mainImages.data,
            })
        );
        dispatch(setLoadingHotel("hotel_images"));
        const hotelImages = await hotelsApi.hotelImages(id);
        dispatch(
            setHotel({
                hotel_images: hotelImages.data,
            })
        );
        dispatch(getOffers(id));
    } catch (err) {
        dispatch(setLoading("hotel"));
        throw new Error(err);
    }
};
const getOffers = (id) => async (dispatch, getState) => {
    try {
        const {data} = getState().hotels.results;
        dispatch(setLoadingHotel("offers"));
        const res = await hotelsApi.offers({
            hotel_id: id,
            srk: data.srk,
            results_token: data.tokens.results,
        });
        dispatch(
            setHotel({
                offers: res.data,
            })
        );
    } catch (err) {
        // dispatch(setLoading("results"));
        throw new Error(err);
    }
};
const showOfferDetails =
    (offer_id, room, tokens) => async (dispatch, getState) => {
        try {
            const {data} = getState().hotels.results;
            const {hotel_id} = getState().hotels.hotel;
            dispatch(setLoadingHotel("room"));
            const res = await hotelsApi.offerDetails({
                hotel_id: hotel_id,
                srk: data.srk,
                results_token: data.tokens.results,
                offer_id: offer_id,
                room_id: room.index,
            });
            dispatch(
                setSelectedRoom({
                    offer_id: offer_id,
                    main: room,
                    more_details: res.data.data,
                    roomTokens: tokens.roomTokens,
                    packageToken: tokens.packageToken,
                })
            );
        } catch (err) {
            // dispatch(setLoading("results"));
            throw new Error(err);
        }
    };
const preBooking = () => async (dispatch, getState) => {
    try {
        const {data} = getState().hotels.results;
        const {hotel_id} = getState().hotels.hotel;
        const {offer_id, main, packageToken, roomTokens} =
            getState().hotels.hotel.data.room;

        dispatch(setLoadingHotel("pre_booking"));
        const res = await hotelsApi.preBooking({
            hotel_id: hotel_id,
            srk: data.srk,
            results_token: data.tokens.results,
            offer_id: offer_id,
            room_id: main.index,
            data: {
                packageToken: packageToken,
                roomTokens: [roomTokens],
            },
        });
        dispatch(setPreBooking(res.data));
    } catch (err) {
        // dispatch(setLoading("results"));
        throw new Error(err);
    }
};
const Book = (travelers) => async (dispatch, getState) => {
    try {
        const {room, pre_booking} = getState().hotels.hotel.data;
        const {data} = getState().hotels.results;
        const {hotel_id} = getState().hotels.hotel;
        const {offer_id} = getState().hotels.hotel.data.room;

        dispatch(setLoadingHotel("book"));
        const bookData = {
            clientRef: Math.random().toString(36).substring(7),
            availabilityToken: pre_booking.data.availabilityToken,
            payment: {
                method: "credit",
            },
            rooms: [
                {
                    packageRoomToken: room.roomTokens,
                    travelers: travelers,
                },
            ],
        };
        const res = await hotelsApi.Booking({
            hotel_id: hotel_id,
            offer_id: offer_id,
            srk: data.srk,
            results_token: data.tokens.results,
            data: bookData,
        });
        if (res.data.status) {
            dispatch(setLoadingHotel("book"));
            toast.success("Successfully Booked!");
            dispatch(setDialog());
            dispatch(clearHotel());
        } else {
            dispatch(setLoadingHotel("book"));
            toast.error("Error Booking!");
        }
    } catch (err) {
        dispatch(setLoadingHotel("book"));
        toast.error("Error Booking!");
        throw new Error(err);
    }
};

export const hotels = {
    getSearchData,
    getByID,
    getOffers,
    showOfferDetails,
    preBooking,
    Book,
};
