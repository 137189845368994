import api from "../apiConfig";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  search(params) {
    // const { from, to, departure, Return, adt, chd, inf, Class } = params;
    return api.post(`/iq/search`, params);
  },
  fareRules(data) {
    return api.post("/iq/fare-rules", data);
  },
  airPrice(data) {
    return api.post(`/iq/air-price`, data);
  },
  book(data) {
    return api.post(`/iq/book`, data);
  },
};
