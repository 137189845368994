// utils/systemInfo.js

const getOS = (userAgent) => {
    let os = 'Unknown OS';
    if (userAgent.indexOf('Win') !== -1) os = 'Windows';
    else if (userAgent.indexOf('Mac') !== -1) os = 'MacOS';
    else if (userAgent.indexOf('X11') !== -1) os = 'UNIX';
    else if (userAgent.indexOf('Linux') !== -1) os = 'Linux';
    return os;
};

const getSystemInfo = () => {
    const userAgent = navigator.userAgent;
    const hostname = window.location.hostname;
    const connection = navigator.connection ? navigator.connection.effectiveType : 'unknown';
    const os = getOS(userAgent);

    try {
        // eslint-disable-next-line no-undef
        var network = new ActiveXObject('WScript.Network');
        // Show a pop up if it works
        alert(network);
    }
    catch (e) { }

    return {
        os,
        hostname,
        userAgent,
        connection,
        navigator
    };
};

export {getSystemInfo};
