import {createSlice} from "@reduxjs/toolkit";
import {setGlobalTravelPort} from "./travelportSlice";
import {setGlobalFlyBaghdad} from "./flyBaghdadSlice";
import {setGlobalAirArabia} from "./airArabiaSlice";
import {setGlobalTurkish} from "./turkishSlice";
import {setGlobalFlyDubai} from "./flydubaiSlice";
import {setGlobalAgora} from "./agoraSlice";
import Cookies from "js-cookie";

import {factory} from "../../api/apiFactory";
import Swal from "sweetalert2";
import dayjs from "dayjs";

const globalApi = factory.get("global");

const initialState = {
    filters: {
        legs: [
            // {
            //   from: "SHJ",
            //   to: "LHR",
            //   date: "2023-11-29",
            // },
        ],
        return: false,
        adt: "1",
        chd: "0",
        inf: "0",
        class: "Economy",
        isMulti: false,
    },
    results: {
        loading: false,
        data: [],
        filters: [],
        sorts: [],
        total_data: 0,
        error_code: 0,
        status: false,
        description: "",
        message: "",
        searchTrackId: "",
    },
    selected_filter: null,
    loading_filter: false,
    loadingNextPrev: false,
    dialog: {
        status: false,
        air_provider: "",
    },
};

export const globalSlice = createSlice({
    name: "global",
    initialState,
    reducers: {
        setResults(state, {payload}) {
            state.results = {
                ...payload,
            };
            state.results.loading = false;
        },
        setResetFiltersAndSorts(state) {
            state.results.filters = [];
            state.results.sorts = [];
        },
        setLoading(state, {payload}) {
            state[payload].loading = !state[payload].loading;
        },
        setDialog(state, {payload}) {
            state.dialog.air_provider = payload ? payload : "";
            state.dialog.status = !state.dialog.status;
        },

        setFilter(state, {payload}) {
            state.filters = payload;
        },
        setLoadingFilter(state) {
            state.loading_filter = !state.loading_filter;
        },
        setSelectedFilter(state, {payload}) {
            state.selected_filter = payload;
        },
        setLoadingNextPrev(state) {
            state.loadingNextPrev = !state.loadingNextPrev;
        },
    },
});

export const {
    setResults,
    setLoading,
    setDialog,
    setFilter,
    setLoadingFilter,
    setResetFiltersAndSorts,
    setLoadingNextPrev,
} = globalSlice.actions;

export default globalSlice.reducer;

//axios
const search = () => async (dispatch, getState) => {
    try {
        const filters = getState().global.filters;
        dispatch(setLoading("results"));
        const res = await globalApi.search(filters);
        dispatch(setResults(res.data));
    } catch (err) {
        // check http status code
        if (err.response.status === 401) {
            Cookies.remove("token_mtn_jwt");
            window.location.href = "/";
        }

        // sweet alert 2 ok to redirect
        Swal.fire({
            icon: "info",
            title: err.response.data.message,
            text: err.response.data.description,
            showCancelButton: false,
            confirmButtonText: "OK",
        }).then((result) => {
            if (result.isConfirmed) {
                window.location.href = "/flights";
            }
        });
    }
};


const nextDaySearch = () => async (dispatch, getState) => {
    try {
        const filters = getState().global.filters;

        // reset results filters
        dispatch(setResetFiltersAndSorts());
        dispatch(setLoadingNextPrev());

        // modify the date in legs with days js
        const updatedLegs = filters.legs.map((leg) => {
            const date = dayjs(leg.date).add(1, "day").format("YYYY-MM-DD");
            return {...leg, date};
        });

        // Update the legs property in the filters object
        const updatedFilters = {...filters, legs: updatedLegs};

        dispatch(setFilter(updatedFilters));
        dispatch(setLoading("results"));
        const res = await globalApi.search(updatedFilters);
        dispatch(setResults(res.data));
        dispatch(setLoadingNextPrev());
    } catch (err) {
        dispatch(setLoading("results"));
        dispatch(setLoadingNextPrev());
        Swal.fire({
            icon: "info",
            title: err.response.data.message,
            text: err.response.data.description,
        });

        // Set a timeout to hide the modal after 3 seconds
        const timeoutId = setTimeout(() => {
            // Hide SweetAlert2 modal
            Swal.close();
        }, 3000);

        // Clear the timeout on component unmount to prevent memory leaks
        return () => clearTimeout(timeoutId);
    }
};


const prevDaySearch = () => async (dispatch, getState) => {
    try {
        dispatch(setResetFiltersAndSorts());
        dispatch(setLoadingNextPrev());

        const filters = getState().global.filters;

        // modify the date in legs with days js
        const updatedLegs = filters.legs.map((leg) => {
            const date = dayjs(leg.date).subtract(1, "day").format("YYYY-MM-DD");
            return {...leg, date};
        });

        // Update the legs property in the filters object
        const updatedFilters = {...filters, legs: updatedLegs};

        dispatch(setFilter(updatedFilters));
        dispatch(setLoading("results"));
        const res = await globalApi.search(updatedFilters);
        dispatch(setResults(res.data));
        dispatch(setLoadingNextPrev());
    } catch (err) {
        dispatch(setLoading("results"));
        dispatch(setLoadingNextPrev());
        Swal.fire({
            icon: "info",
            title: err.response.data.message,
            text: err.response.data.description,
        });

        // Set a timeout to hide the modal after 3 seconds
        const timeoutId = setTimeout(() => {
            // Hide SweetAlert2 modal
            Swal.close();
        }, 3000);

        // Clear the timeout on component unmount to prevent memory leaks
        return () => clearTimeout(timeoutId);
    }
};


const sortFilter = (sort, queryFilters) => async (dispatch, getState) => {
    try {
        const searchTrackId = getState().global.results.searchTrackId;

        dispatch(setLoadingFilter());
        const res = await globalApi.sortFilter(
            {sort, queryFilters},
            searchTrackId
        );
        dispatch(setResults(res.data));
        dispatch(setLoadingFilter());
    } catch (err) {
        // dispatch(setLoading("results"));
        // throw new Error(err);
        console.log(err);
    }
};
const setByProvider =
    (providerType, providerData) => async (dispatch, getState) => {
        try {
            const {adt, chd, inf} = getState().global.filters;

            const travelers = Number(adt) + Number(chd) + Number(inf);
            const passengers = {
                ADT: Number(adt),
                CNN: Number(chd),
                INF: Number(inf),
            };
            const data = {
                ...providerData,
                passengers,
                travelers,
            };

            switch (providerType) {
                case "BBBG":
                    await dispatch(setGlobalTravelPort(data));
                    break;
                case "fly_baghdad":
                    await dispatch(setGlobalFlyBaghdad(data));
                    break;
                case "airarabia":
                    await dispatch(setGlobalAirArabia(data));
                    break;
                case "ABCT":
                    await dispatch(setGlobalTurkish(data));
                    break;
                case "AAAF":
                    await dispatch(setGlobalFlyDubai(data));
                    break;
                case "AGGG":
                    await dispatch(setGlobalAgora(data));
                    break;
                default:
                    break;
            }
            dispatch(setDialog(providerType));
        } catch (err) {
            // throw new Error(err);
            console.log(err);
        }
    };

export const global = {
    search,
    setByProvider,
    sortFilter,
    nextDaySearch,
    prevDaySearch
};
