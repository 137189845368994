import OneSignal from "react-onesignal";

export default async function runOneSignal() {
    await OneSignal.init({
        appId: process.env.REACT_APP_ONESIGNAL_APP_ID,
        allowLocalhostAsSecureOrigin: true,
        serviceWorkerParam: {scope: "/"},
        serviceWorkerPath: "serviceWorker.js",
    }, (error) => {
        if (error) {
            console.error("OneSignal init failed:", error);
        }
    });

    OneSignal.showSlidedownPrompt();
}
