import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import toast from "react-hot-toast";
import { factory } from "../../api/apiFactory";
import Swal from "sweetalert2";

const iraqiApi = factory.get("iraqi");
const othersApi = factory.get("others");

const initialState = {
  filters: {
    legs: [
      // {
      //   from: "IST",
      //   to: "LHR",
      //   date: dayjs().add(30, "day").format("YYYY-MM-DD"),
      // },
    ],
    return: false,
    adt: "1",
    chd: "0",
    inf: "0",
    class: "Economy",
  },
  results: {
    loading: false,
    data: [],
    selected_index: null,
    description: "",
    message: "",
  },
  request_object: {
    segments: [],
    passengers: null,
    travelers: 0,
    pricing: {
      currency: "USD",
    },
    long: true,
    requestId: Math.floor(100000 + Math.random() * 900000),
  },
  trip_details: {
    modal: false,
    flight: {
      directions: [],
    },
    price: {
      loading: false,
      data: {
        farePricingMethod: "Guaranteed",
        platingCarrier: "",
        totalPrice: "",
        basePrice: "",
        taxes: "",
        passengerFares: {
          ADT: {
            totalPrice: "",
            basePrice: "",
            taxes: "",
          },
        },
        fareInfo: {
          latestTicketingTime: "",
          eTicketability: "",
          refundable: false,
          changePenalty: {
            amount: "",
            penaltyApplies: "",
          },
          cancelPenalty: {
            percentage: "",
            penaltyApplies: "",
          },
        },
        segmants: [],
        taxesInfo: [],
        baggage: [],
        timeToReprice: "",
      },
    },
    fare_rules: {
      loading: false,
      data: [],
    },
  },
  // price_details: {
  //   loading: false,
  //   Fares: [],
  //   Policy: {},
  //   Keys: [],
  //   privateDate: "",
  // },
  book: {
    loading: false,
    bookingType: "PreBooking",
    errors: "",
  },
};

export const iraqiAirwaysSlice = createSlice({
  name: "iraqi",
  initialState,
  reducers: {
    setResults(state, { payload }) {
      state.results.data = payload.data.length > 0 ? payload.data : [];
      state.results.loading = false;
    },
    setRequestObject(state, { payload }) {
      state.request_object = { ...payload };
    },
    setKeysAndIndex(state, { payload }) {
      state.keys_request = { ...payload.keys_request };
      state.results.selected_index = payload.selected_index;
    },
    setFlight(state, { payload }) {
      state.trip_details.flight = payload;
    },
    setBookErrors(state, { payload }) {
      state.book.errors = payload;
    },
    toggleTripDetailsModal(state, { payload }) {
      state.trip_details.modal = payload;
    },
    setLoading: (state, { payload }) => {
      switch (payload) {
        case "results":
          state.results.loading = !state.results.loading;
          break;
        case "price":
          state.trip_details.price.loading = !state.trip_details.price.loading;
          break;
        case "fare_rules":
          state.trip_details.fare_rules.loading =
            !state.trip_details.fare_rules.loading;
          break;
        case "book":
          state.book.loading = !state.book.loading;
          break;
        default:
      }
    },
    setLegs(state, { payload }) {
      state.filters.legs.findIndex((leg, index) => {
        if (index === payload.index) {
          state.filters.legs[index][payload.key] = payload.value;
        }
      });
    },
    addLeg(state) {
      state.filters.legs.push({
        from: "",
        to: "",
        date: dayjs().add(1, "day").format("YYYY-MM-DD"),
      });
    },
    removeLeg(state, { payload }) {
      state.filters.legs.splice(payload, 1);
    },
    resetLegs(state) {
      state.filters.legs = initialState.filters.legs;
    },
    setFilters(state, { payload }) {
      state.filters = {
        ...state.filters,
        ...payload,
      };
    },
    setFareRules(state, { payload }) {
      state.trip_details.fare_rules.data = payload;
    },
    setPrice(state, { payload }) {
      state.trip_details.price.data = { ...payload };
      const directions1 = payload?.directions[0][0].segments;
      const directions2 =
        payload?.directions.length > 1
          ? payload?.directions[1][0].segments
          : [];

      state.trip_details.price.data.segmants = [...directions1, ...directions2];
    },
    setFilter(state, { payload }) {
      state.filters = payload;
    },
    setBookingType(state, { payload }) {
      state.book.bookingType = payload;
    },
    setGlobalTurkish(state, { payload }) {
      //flight object
      state.trip_details.flight.directions = payload.directions;
      state.trip_details.price.data.bookingComponents =
        payload.bookingComponents;
      state.trip_details.price.data.passengerCounts = payload.passengerCounts;
      state.trip_details.price.data.passengerFares = payload.passengerFares;
      state.trip_details.price.data.platingCarrier = payload.platingCarrier;
      state.trip_details.price.data.taxes = payload.taxes;
      state.trip_details.price.data.totalPrice = payload.totalPrice;
      state.trip_details.price.data.basePrice = payload.basePrice;
      //request object
      const directions1 = payload.directions[0][0].segments;
      const directions2 = payload.directions.length > 1 ? payload.directions[1][0].segments : [];
      const direction3 = payload.directions.length > 2 ? payload.directions[2][0].segments : [];
      const direction4 = payload.directions.length > 3 ? payload.directions[3][0].segments : [];
      const direction5 = payload.directions.length > 4 ? payload.directions[4][0].segments : [];
      state.request_object.segments = [...directions1, ...directions2, ...direction3, ...direction4, ...direction5];
      state.request_object.passengers = payload.passengerCounts;
      state.request_object.travelers =
        payload.passengerCounts.ADT +
        payload.passengerCounts?.CNN +
        payload.passengerCounts?.INF;
    },
  },
});

export const {
  setSearchValues,
  setKeysAndIndex,
  setLoading,
  setResults,
  setRequestObject,
  toggleTripDetailsModal,
  setPrice,
  setFareRules,
  setFlight,
  setBookErrors,
  setFilters,
  setLegs,
  addLeg,
  removeLeg,
  resetLegs,
  setFilter,
  setBookingType,
  setGlobalTurkish,
} = iraqiAirwaysSlice.actions;

export default iraqiAirwaysSlice.reducer;

//axios
const search = () => async (dispatch, getState) => {
  try {
    const filters = getState().iraqi.filters;
    dispatch(setLoading("results"));
    const res = await iraqiApi.search(filters);
    dispatch(setResults(res.data));
  } catch (err) {
    dispatch(setLoading("results"));
    toast("Something went wrong !");
    // throw new Error(err);
  }
};
const fareRulesDetails = () => async (dispatch, getState) => {
  try {
    const request_object = getState().iraqi.request_object;
    dispatch(setLoading("fare_rules"));
    const res = await iraqiApi.fareRules(request_object);
    dispatch(setFareRules(res.data.data));
    dispatch(setLoading("fare_rules"));
  } catch (err) {
    dispatch(setLoading("fare_rules"));
    throw new Error(err);
  }
};
const airPriceDetails = () => async (dispatch, getState) => {
  try {
    const request_object = getState().iraqi.request_object;
    dispatch(setLoading("price"));
    const res = await iraqiApi.airPrice(request_object);
    dispatch(setPrice(res.data.data));
    dispatch(setLoading("price"));
  } catch (err) {
    dispatch(setLoading("price"));
    throw new Error(err);
  }
};
const book = (form, contact, attachments) => async (dispatch, getState) => {
  try {
    dispatch(setLoading("book"));
    let existAttachments = false;
    const request_object = getState().iraqi.request_object;
    const { bookingType } = getState().iraqi.book;
    const { segmants } = getState().iraqi.trip_details.price.data;
    const formData = new FormData();

    for (let i = 0; i < attachments.length; i++) {
      existAttachments = true;
      // formData.append(`files[${i}]`, JSON.stringify(attachments[i]));
      formData.append(`files[${i}]`, attachments[i]);
    }

    const bookInfo = {
      segments: segmants,
      passengers: form,
      phone: contact,
      allowWaitlist: true,
      bookingType, // PreBooking or Booking
    };

    const book_res = await iraqiApi.book(bookInfo);

    if (book_res.data.data != null) {
      if(existAttachments){
        const booking_res_success = book_res.data.data;
        formData.append("booking_id", booking_res_success.id);
        formData.append("pnr", booking_res_success.pnr);
        const attachments_res = await othersApi.attachments(formData);
        if (attachments_res.data.status) {
          toast.success("Successfully Booked!");
          dispatch(setBookErrors(""));
          return true;
        } else {
          toast.error("Somthing went wrong !");
          dispatch(setBookErrors(attachments_res.data.description));
        }
      }
      toast.success("Successfully Booked!");
      dispatch(setBookErrors(""));

      if (book_res.data.data.TicketLink) {
        // check the link if it has http or https
        if (book_res.data.data.TicketLink.includes("http") || book_res.data.data.TicketLink.includes("https")) {
          window.open(book_res.data.data.TicketLink, "_blank");
        } else {
          window.open("http://" + book_res.data.data.TicketLink, "_blank");
        }
      }

      window.location.href = "/profile";
    } else {
      toast.error("Somthing went wrong !");
      dispatch(setBookErrors(book_res.data.description));
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
    dispatch(setLoading("book"));
  } catch (err) {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    dispatch(setLoading("book"));
    toast.error("Somthing went wrong !");
    dispatch(setBookErrors(err.response.data.description));
    throw new Error(err);
  }
};

const nextDaySearch = () => async (dispatch, getState) => {
  try {
    const filters = getState().iraqi.filters;

    // modify the date in legs with days js
    const updatedLegs = filters.legs.map((leg) => {
      const date = dayjs(leg.date).add(1, "day").format("YYYY-MM-DD");
      return {...leg, date};
    });

    // Update the legs property in the filters object
    const updatedFilters = {...filters, legs: updatedLegs};

    dispatch(setFilter(updatedFilters));
    dispatch(setLoading("results"));
    const res = await iraqiApi.search(updatedFilters);
    dispatch(setResults(res.data));
  } catch (err) {
    dispatch(setLoading("results"));

    Swal.fire({
      icon: "info",
      title: err.response.data.message,
      text: err.response.data.description,
    });

    // Set a timeout to hide the modal after 3 seconds
    const timeoutId = setTimeout(() => {
      // Hide SweetAlert2 modal
      Swal.close();
    }, 3000);

    // Clear the timeout on component unmount to prevent memory leaks
    return () => clearTimeout(timeoutId);
  }
};


const prevDaySearch = () => async (dispatch, getState) => {
  try {
    const filters = getState().iraqi.filters;

    // modify the date in legs with days js
    const updatedLegs = filters.legs.map((leg) => {
      const date = dayjs(leg.date).subtract(1, "day").format("YYYY-MM-DD");
      return {...leg, date};
    });

    // Update the legs property in the filters object
    const updatedFilters = {...filters, legs: updatedLegs};

    dispatch(setFilter(updatedFilters));
    dispatch(setLoading("results"));
    const res = await iraqiApi.search(updatedFilters);
    dispatch(setResults(res.data));
  } catch (err) {
    dispatch(setLoading("results"));

    Swal.fire({
      icon: "info",
      title: err.response.data.message,
      text: err.response.data.description,
    });

    // Set a timeout to hide the modal after 3 seconds
    const timeoutId = setTimeout(() => {
      // Hide SweetAlert2 modal
      Swal.close();
    }, 3000);

    // Clear the timeout on component unmount to prevent memory leaks
    return () => clearTimeout(timeoutId);
  }
};

export const iraqi = {
  search,
  fareRulesDetails,
  airPriceDetails,
  book,
  nextDaySearch,
  prevDaySearch
};
