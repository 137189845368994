import { createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import { factory } from "../../api/apiFactory";
const flyBaghdadApi = factory.get("flyBaghdad");
const othersApi = factory.get("others");

const initialState = {
  results: {
    loading: false,
    data: [],
    status: false,
    description: "",
    message: "",
  },
  request_object: {
    segments: [],
    DirectionInd: "",
    FarePrices: {},
    FareRules: {},
    AirTravelers: [],
    travelers: 0,
    ContactPerson: {
      GivenName: "",
      Surname: "",
      Telephone: "",
      PhoneCode: "",
      Email: "",
    },
    BookingType: "Booking",
  },
  book: {
    loading: false,
    errors: "",
  },
};

export const flyBaghdadSlice = createSlice({
  name: "flyBaghdad",
  initialState,
  reducers: {
    setResults(state, { payload }) {
      if (payload.data != null) {
        state.results.data = payload.data;
      } else {
        state.results = { ...payload, data: [] };
      }
      state.results.loading = false;
    },
    setBookErrors(state, { payload }) {
      state.book.errors = payload;
    },
    setRequestObject(state, { payload }) {
      state.request_object = { ...payload };
    },
    toggleTripDetailsModal(state, { payload }) {
      state.trip_details.modal = payload;
    },
    setLoading: (state, { payload }) => {
      state.results.loading = !state.results.loading;
    },
    setGlobalFlyBaghdad(state, { payload }) {
      //flight object
      state.request_object.DirectionInd = payload.DirectionInd;
      state.request_object.segments = payload.segments;
      state.request_object.FarePrices = payload.farePrices;
      state.request_object.FareRules = payload.fareRule;

      state.request_object.travelers = payload.travelers;
      state.request_object.passengers = payload.passengers;
    },
    setLoadingBook(state) {
      state.book.loading = !state.book.loading;
    }
  },
});

export const {
  setLoading,
  setResults,
  setRequestObject,
  toggleTripDetailsModal,
  setBookErrors,
  setGlobalFlyBaghdad,
  setLoadingBook
} = flyBaghdadSlice.actions;

export default flyBaghdadSlice.reducer;

//axios
const search = (params) => async (dispatch) => {
  try {
    dispatch(setLoading("results"));
    const res = await flyBaghdadApi.search(params);
    dispatch(setResults(res.data));
  } catch (err) {
    dispatch(setLoading("results"));
    // throw new Error(err);
    console.log(err);
  }
};
const book = (form, contact, attachments) => async (dispatch, getState) => {
  try {
    dispatch(setLoadingBook("book"));
    const existAttachments = false;
    const request_object = getState().flyBaghdad.request_object;
    const formData = new FormData();

    for (let i = 0; i < attachments.length; i++) {
      existAttachments = true;
      // formData.append(`files[${i}]`, JSON.stringify(attachments[i]));
      formData.append(`files[${i}]`, attachments[i]);
    }

    const bookInfo = {
      segments: request_object.segments,
      DirectionInd: request_object.DirectionInd,
      FarePrices: request_object.FarePrices,
      AirTravelers: form,
      ContactPerson: contact,
      BookingType: "Booking",
    };
    const book_res = await flyBaghdadApi.book(bookInfo);
    dispatch(setLoadingBook("book"));
    if (book_res.data.data != null) {
      if (existAttachments) {
        const booking_res_success = book_res.data.data;
        formData.append("booking_id", booking_res_success.id);
        formData.append("pnr", booking_res_success.pnr);

        const attachments_res = await othersApi.attachments(formData);
        if (attachments_res.data.status) {
          toast.success("Successfully Booked!");
          dispatch(setBookErrors(""));
          return true;
        } else {
          toast.error("Somthing went wrong !");
          dispatch(setBookErrors(attachments_res.data.description));
        }
      }

      toast.success("Successfully Booked!");
      dispatch(setBookErrors(""));
      window.location.href = "/profile";
    } else {
      toast.error("Somthing went wrong !");
      dispatch(setBookErrors(book_res.data.description));
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  } catch (err) {
    dispatch(setLoadingBook("book"));
    // dispatch(setBookErrors(err.response.data.description));
    // throw new Error(err);
    console.log(err);
  }
};

export const flyBaghdad = {
  search,
  book,
};
