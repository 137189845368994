import api from "../apiConfig";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  search(params) {
    const { from, to, departure, Return, adt, chd, inf, Class } = params;
    return api.get(
      `/BBBG/search?from=${from}&to=${to}&departure=${departure}&return=${
        Return ? Return : null
      }&adt=${adt}&chd=${chd}&inf=${inf}&class=${Class}&searchType=lowfare`
    );
  },
  fareRules(data) {
    return api.post(`/BBBG/fare_rules`, data);
  },
  airPrice(data) {
    return api.post(`/BBBG/air-price`, data);
  },
  book(data) {
    return api.post(`/BBBG/book`, data);
  },
};
