import api from "../apiConfig";

const paramsFactory = (p) => {
    const params = new URLSearchParams();
    if (p) {
        Object.keys(p).forEach((key) => {
            if (p[key] !== null && p[key] !== undefined && p[key] !== "") {
                params.append(key, p[key]);
            }
        });
    }
    return params;
};
// eslint-disable-next-line import/no-anonymous-default-export
export default {
    login(data) {
        return api.post("/authentication/login", {
            email: data.email,
            password: data.password,
        });
    },
    logout() {
        return api.get(`/authentication/logout`);
    },
    profile() {
        return api.get(`/authentication/profile`);
    },
    transactions(p) {
        const params = paramsFactory(p);
        return api.get(`/authentication/transactions?${params}`);
    },
    booking(p) {
        const params = paramsFactory(p);
        return api.get(`/authentication/booking?${params}`);
    },
    notificationsHistory(p) {
        const params = paramsFactory(p);
        return api.get(`/authentication/notifications?${params}`);
    },
    commissions(p) {
        const params = paramsFactory(p);
        return api.get(`/authentication/commissions?${params}`);
    },
    confirmBooking(params) {
        return api.get(
            `/authentication/booking/action/${params.id}/${params.type}`
        );
    },
    resetPassword(email) {
        return api.get(`/authentication/reset-password?email=${email}`);
    },
    changePassword(data) {
        return api.post(`/authentication/change-password`, data);
    },
    changeLanguage(lang) {
        return api.get(`/authentication/change-lang?lang=${lang}`);
    },
    exportTransactions() {
        return api.get(`/authentication/export-transactions`);
    },
    exportBooking() {
        return api.get(`/authentication/export-bookings`);
    },

};
