import axios from "axios";
import Cookies from "js-cookie";

const authInterceptor = (config) => {
    const token = Cookies.get("token_mtn_jwt");

    // config.headers["Authorization"] = `Bearer ${store.getters["auth/getToken"]}`;
    // config.headers["lang"] = store.getters["app/lang"];
    config.headers["Authorization"] = `Bearer ${token}`;
    config.headers["Content-Type"] = "application/json";

    const lang = Cookies.get("lang");

    if (lang) {
        config.headers["Accept-Language"] = lang;
    } else {
        config.headers["Accept-Language"] = "en";
    }

    return config;
};

const api = axios.create({
    baseURL: process.env.REACT_APP_API,
    timeout: 600000, // 10 minutes
});


api.interceptors.request.use(authInterceptor);

api.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response.status === 401) {
            Cookies.remove("token_mtn_jwt");
            window.location.href = "/";
        }
        return Promise.reject(error);
    }
);
export default api;
