import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {visa} from "src/store/slices/visaSlice";
import CardsLayoutV from "../../components/Cards/CardsLayoutV";
import {useTranslation} from "react-i18next";
import {setFilters} from "../../../../store/slices/visaSlice";

const Index = () => {
    const {t} = useTranslation();

    const execute = useDispatch();
    const {filters} = useSelector((state) => state.visa);
    const countryId = window.location.href.split("/").pop();


    useEffect(() => {
        execute(setFilters({ ...filters, country_id: countryId }));
        execute(visa.getData());
    }, []);

    return (
        <div className="flex flex-col py-4 gap-10 lg:gap-4">
            <h1 className="text-3xl font-bold">{t("sidebar.visa")}</h1>
            <div className="flex flex-col gap-4">
                {/*<Filter/>*/}
                <CardsLayoutV/>
                 {/*<GroupsCard />*/}
            </div>
        </div>
    );
};

export default Index;
