// src/components/LoginModal.js

import {Fragment, useEffect, useState} from "react";
import {Dialog, Transition} from "@headlessui/react";
import loginIcon from "../../assets/images/login.svg";
import {profile as ProfileAction} from "src/store/slices/profileSlice";
import {useDispatch, useSelector} from "react-redux";
import {MdVisibility, MdVisibilityOff} from 'react-icons/md';
import Cookies from "js-cookie";
import toast from "react-hot-toast";
import {t} from "i18next";
import {Mixpanel} from "../../mixpanel/Mixpanel";
import * as sweetalert2 from "sweetalert2";

export default function LoginModal() {
    let [is_forgetpass, setIsForgetPass] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false); // New state for show/hide password
    const execute = useDispatch();
    const {profile} = useSelector((state) => state.profile);
    const token = Cookies.get("token_mtn_jwt");
    const [showLoginForm, setShowLoginForm] = useState(true);

    const [location, setLocation] = useState({latitude: null, longitude: null});
    const [error, setError] = useState(null);
    const [systemInfo, setSystemInfo] = useState({});

    useEffect(() => {
        // Use the global function to get system info
        const info = window.getSystemInfo();
        setSystemInfo(info);
    }, []);

    const handleGetLocation = () => {
        window.getLocation()
            .then((position) => {
                setLocation(position);
                setError(null);
            })
            .catch((err) => {
                setError(err.message);
            });
    };

    // call handleGetLocation one time on page load
    useEffect(() => {
        handleGetLocation();
    }, []);

    // call mixpanel track
    useEffect(() => {
        Mixpanel.track("Login", {
            "Page Name": "Login",
            "System Info": systemInfo,
            "Location": location,
            "Error": error,
        });
    }, [location, error]);

    // check if user does not have permission to access location
    useEffect(() => {
        if (error) {
            setShowLoginForm(false);
            sweetalert2.default.fire({
                title: "Error",
                text: "You must enable location to use our service",
                icon: "error",
                confirmButtonText: "OK",
            });
        }
    }, [error]);

    const onLoginSubmit = (e) => {
        e.preventDefault();
        if (!is_forgetpass) {
            if (email && password) {
                execute(ProfileAction.login({email, password}));
            }
        } else {
            if (email) {
                execute(ProfileAction.resetPassword(email))
                    .then((res) => {
                        setEmail("");
                        toast.success(t("messages.resetpasswordemail"));
                        Mixpanel.track("Reset Password", {
                            "Email": email,
                        });
                    })
                    .catch((err) => {
                        toast.error(t("messages.error"));
                        Mixpanel.track("Reset Password Error", {
                            "Email": email,
                            "Error": "Error sending reset password email",
                        });
                    });
            }
        }
    };

    return (
        <>
            <Transition
                appear
                show={!token && (profile.email === "" || profile.status)}
                as={Fragment}
            >
                <Dialog as="div" className="relative z-40" style={{zIndex: 1000}} onClose={() => {
                }}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-white bg-opacity-100"/>
                    </Transition.Child>
                    {showLoginForm ? (
                        <div className="fixed inset-0 overflow-y-auto">
                            <div className="flex min-h-full items-center justify-center p-4 text-center">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 scale-95"
                                    enterTo="opacity-100 scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 scale-100"
                                    leaveTo="opacity-0 scale-95"
                                >
                                    <Dialog.Panel
                                        className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                        <Dialog.Title
                                            as="h2"
                                            className="text-2xl font-medium leading-6 text-gray-900 my-2"
                                        >
                                            {t("login.Signin")}
                                        </Dialog.Title>
                                        <img
                                            alt="login"
                                            src={loginIcon}
                                            width={100}
                                            height={1}
                                            className="bg-cover mx-auto w-full"
                                        />
                                        <form onSubmit={onLoginSubmit}>
                                            {!is_forgetpass ? (
                                                <div className="mt-2 flex flex-col gap-2">
                                                    {/* EMAIL */}
                                                    <div className="relative">
                                                        <label
                                                            htmlFor="name-with-label"
                                                            className="text-gray-700"
                                                        >
                                                            {t("book.email")}
                                                        </label>
                                                        <input
                                                            value={email}
                                                            onChange={(e) => setEmail(e.target.value)}
                                                            type="email"
                                                            id="name-with-label"
                                                            className="rounded-lg border-transparent flex-1  border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-orange-600 focus:border-transparent"
                                                            name="email"
                                                            required
                                                            placeholder={t("book.email")}
                                                            autoComplete="off"
                                                        />
                                                    </div>
                                                    {/* PASSWORD */}
                                                    <div className="relative">
                                                        <label
                                                            htmlFor="name-with-label"
                                                            className="text-gray-700"
                                                        >
                                                            {t("login.Password")}
                                                        </label>
                                                        <div className="flex items-center">
                                                            <input
                                                                value={password}
                                                                type={showPassword ? 'text' : 'password'}
                                                                onChange={(e) => setPassword(e.target.value)}
                                                                id="password-with-label"
                                                                className="rounded-lg border-transparent flex-1  border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-orange-600 focus:border-transparent"
                                                                name="password"
                                                                required
                                                                placeholder={t('login.Passwordhint')}
                                                                autoComplete="off"
                                                            />
                                                            <div
                                                                className="ml-2 text-gray-700 cursor-pointer"
                                                                onClick={() => setShowPassword(!showPassword)}
                                                            >
                                                                {showPassword ? (
                                                                    <MdVisibilityOff size={20}/>
                                                                ) : (
                                                                    <MdVisibility size={20}/>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className="relative">
                                                    <label
                                                        htmlFor="name-with-label"
                                                        className="text-gray-700"
                                                    >
                                                        {t("book.email")}
                                                    </label>
                                                    <input
                                                        value={email}
                                                        onChange={(e) => setEmail(e.target.value)}
                                                        type="email"
                                                        id="name-with-label"
                                                        className="rounded-lg border-transparent flex-1  border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-orange-600 focus:border-transparent"
                                                        name="email"
                                                        required
                                                        placeholder={t("book.email")}
                                                        autoComplete="off"
                                                    />
                                                </div>
                                            )}

                                            {!is_forgetpass && (
                                                <div className="flex-row items-center gap-1 my-2">
                                                    <div
                                                        role="button"
                                                        onClick={() => {
                                                            setIsForgetPass(true);
                                                        }}
                                                        className="text-blue-500"
                                                    >
                                                        {t("login.forgotpassword")}
                                                    </div>
                                                    <span className="text-sm text-gray-400">
                            {t("login.msgcontactus")}
                          </span>
                                                </div>
                                            )}
                                            {is_forgetpass && (
                                                <div className="flex flex-col gap-1 my-2">
                          <span className="text-sm text-gray-400">
                            {t("login.msgcontactus2")}
                          </span>
                                                    <div
                                                        role="button"
                                                        onClick={() => {
                                                            setIsForgetPass(false);
                                                        }}
                                                        className="text-blue-500"
                                                    >
                                                        {t("login.gologin")}
                                                    </div>
                                                </div>
                                            )}
                                            <div className="mt-4 mx-auto flex flex-1 w-full">
                                                <button
                                                    type="submit"
                                                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                                >
                                                    {profile.loading && (
                                                        <svg
                                                            className="h-4 w-4 animate-spin fill-orange-300"
                                                            viewBox="3 3 18 18"
                                                        >
                                                            <path
                                                                d="M12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5ZM3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12Z"></path>
                                                            <path
                                                                className="fill-white"
                                                                d="M16.9497 7.05015C14.2161 4.31648 9.78392 4.31648 7.05025 7.05015C6.65973 7.44067 6.02656 7.44067 5.63604 7.05015C5.24551 6.65962 5.24551 6.02646 5.63604 5.63593C9.15076 2.12121 14.8492 2.12121 18.364 5.63593C18.7545 6.02646 18.7545 6.65962 18.364 7.05015C17.9734 7.44067 17.3403 7.44067 16.9497 7.05015Z"
                                                            ></path>
                                                        </svg>
                                                    )}
                                                    {profile.loading
                                                        ? t("loading")
                                                        : !is_forgetpass
                                                            ? t("login.Signin")
                                                            : t("login.senpass")}
                                                </button>
                                            </div>
                                        </form>
                                    </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </div>
                    ) : null}
                </Dialog>
            </Transition>
        </>
    );
}


// import {Fragment, useEffect, useState} from "react";
// import { Dialog, Transition } from "@headlessui/react";
// import loginIcon from "../../assets/images/login.svg";
// import { profile as ProfileAction } from "src/store/slices/profileSlice";
// import { useSelector, useDispatch } from "react-redux";
// import { MdVisibility, MdVisibilityOff } from 'react-icons/md';
// import Cookies from "js-cookie";
// import toast from "react-hot-toast";
// import { t } from "i18next";
// import {Mixpanel} from "../../mixpanel/Mixpanel";
//
// export default function LoginModal() {
//   let [is_forgetpass, setIsForgetPass] = useState(false);
//   const [email, setEmail] = useState("");
//   const [password, setPassword] = useState("");
//   const [showPassword, setShowPassword] = useState(false); // New state for show/hide password
//   const execute = useDispatch();
//   const { profile } = useSelector((state) => state.profile);
//   const token = Cookies.get("token_mtn_jwt");
//   const [showLoginForm, setShowLoginForm] = useState(true);
//
//   const [location, setLocation] = useState({ latitude: null, longitude: null });
//   const [error, setError] = useState(null);
//
//   const handleGetLocation = () => {
//     window.getLocation()
//         .then((position) => {
//           setLocation(position);
//           setError(null);
//         })
//         .catch((err) => {
//           setError(err.message);
//         });
//   };
//
//   // call handleGetLocation one time on page load
//   useEffect(() => {
//     handleGetLocation();
//   }, []);
//
//   // call mixpanel track
//   Mixpanel.track("Home Page", {
//     "Page Name": "Home Page",
//     "Location": location,
//     "Error": error,
//   });
//
//   // check if user is not has permission to access location
//     if (error) {
//         setShowLoginForm(false);
//     }
//
//
//   const onLoginSubmit = (e) => {
//     e.preventDefault();
//     if (!is_forgetpass) {
//       if (email && password) {
//         execute(ProfileAction.login({ email, password }));
//       }
//     } else {
//       if (email) {
//         execute(ProfileAction.resetPassword(email))
//           .then((res) => {
//             setEmail("");
//             toast.success(t("messages.resetpasswordemail"));
//           })
//           .catch((err) => {
//             toast.success(t("messages.error"));
//           });
//       }
//     }
//   };
//
//   return (
//     <>
//       <Transition
//         appear
//         show={!token && (profile.email === "" || profile.status)}
//         as={Fragment}
//       >
//         <Dialog as="div" className="relative z-40" style={{zIndex: 1000}} onClose={() => {}}>
//           <Transition.Child
//             as={Fragment}
//             enter="ease-out duration-300"
//             enterFrom="opacity-0"
//             enterTo="opacity-100"
//             leave="ease-in duration-200"
//             leaveFrom="opacity-100"
//             leaveTo="opacity-0"
//           >
//             <div className="fixed inset-0 bg-white bg-opacity-100" />
//           </Transition.Child>
//           {showLoginForm ? (<div className="fixed inset-0 overflow-y-auto">
//             <div className="flex min-h-full items-center justify-center p-4 text-center">
//               <Transition.Child
//                   as={Fragment}
//                   enter="ease-out duration-300"
//                   enterFrom="opacity-0 scale-95"
//                   enterTo="opacity-100 scale-100"
//                   leave="ease-in duration-200"
//                   leaveFrom="opacity-100 scale-100"
//                   leaveTo="opacity-0 scale-95"
//               >
//                 <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
//                   <Dialog.Title
//                       as="h2"
//                       className="text-2xl font-medium leading-6 text-gray-900 my-2"
//                   >
//                     {t("login.Signin")}
//                   </Dialog.Title>
//                   <img
//                       alt="login"
//                       src={loginIcon}
//                       width={100}
//                       height={1}
//                       className="bg-cover mx-auto w-full"
//                   />
//                   <form onSubmit={onLoginSubmit}>
//                     {!is_forgetpass ? (
//                         <div className="mt-2 flex flex-col gap-2">
//                           {/* EMAIL */}
//                           <div className=" relative ">
//                             <label
//                                 htmlFor="name-with-label"
//                                 className="text-gray-700"
//                             >
//                               {t("book.email")}
//                             </label>
//                             <input
//                                 value={email}
//                                 onChange={(e) => setEmail(e.target.value)}
//                                 type="email"
//                                 id="name-with-label"
//                                 className="rounded-lg border-transparent flex-1  border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-orange-600 focus:border-transparent"
//                                 name="email"
//                                 required
//                                 placeholder={t("book.email")}
//                                 autoComplete="off"
//                             />
//                           </div>
//                           {/* PASSWORD */}
//                           <div className=" relative ">
//                             <label
//                                 htmlFor="name-with-label"
//                                 className="text-gray-700"
//                             >
//                               {t("login.Password")}
//                             </label>
//                             <div className="flex items-center">
//                               <input
//                                   value={password}
//                                   type={showPassword ? 'text' : 'password'}
//                                   onChange={(e) => setPassword(e.target.value)}
//                                   id="password-with-label"
//                                   className="rounded-lg border-transparent flex-1  border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-orange-600 focus:border-transparent"
//                                   name="password"
//                                   required
//                                   placeholder={t('login.Passwordhint')}
//                                   autoComplete="off"
//                               />
//                               <div
//                                   className="ml-2 text-gray-700 cursor-pointer"
//                                   onClick={() => setShowPassword(!showPassword)}
//                               >
//                                 {showPassword ? (
//                                     <MdVisibilityOff size={20} />
//                                 ) : (
//                                     <MdVisibility size={20} />
//                                 )}
//                               </div>
//                             </div>
//                           </div>
//                         </div>
//                     ) : (
//                         <div className=" relative ">
//                           <label
//                               htmlFor="name-with-label"
//                               className="text-gray-700"
//                           >
//                             {t("book.email")}
//                           </label>
//                           <input
//                               value={email}
//                               onChange={(e) => setEmail(e.target.value)}
//                               type="email"
//                               id="name-with-label"
//                               className="rounded-lg border-transparent flex-1  border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-orange-600 focus:border-transparent"
//                               name="email"
//                               required
//                               placeholder={t("book.email")}
//                               autoComplete="off"
//                           />
//                         </div>
//                     )}
//
//                     {!is_forgetpass && (
//                         <div className="flex-row items-center gap-1 my-2">
//                           <div
//                               role="button"
//                               onClick={() => {
//                                 setIsForgetPass(true);
//                               }}
//                               className="text-blue-500"
//                           >
//                             {t("login.forgotpassword")}
//                           </div>
//                           <span className="text-sm text-gray-400">
//                           {t("login.msgcontactus")}
//                         </span>
//                         </div>
//                     )}
//                     {is_forgetpass && (
//                         <div className="flex flex-col  gap-1 my-2">
//                         <span className="text-sm text-gray-400">
//                           {t("login.msgcontactus2")}
//                         </span>
//                           <div
//                               role="button"
//                               onClick={() => {
//                                 setIsForgetPass(false);
//                               }}
//                               className="text-blue-500"
//                           >
//                             {t("login.gologin")}
//                           </div>
//                         </div>
//                     )}
//                     <div className="mt-4 mx-auto flex flex-1 w-full">
//                       <button
//                           type="submit"
//                           className="inline-flex w-full justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
//                       >
//                         {profile.loading && (
//                             <svg
//                                 className="h-4 w-4 animate-spin fill-orange-300"
//                                 viewBox="3 3 18 18"
//                             >
//                               <path d="M12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5ZM3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12Z"></path>
//                               <path
//                                   className="fill-white"
//                                   d="M16.9497 7.05015C14.2161 4.31648 9.78392 4.31648 7.05025 7.05015C6.65973 7.44067 6.02656 7.44067 5.63604 7.05015C5.24551 6.65962 5.24551 6.02646 5.63604 5.63593C9.15076 2.12121 14.8492 2.12121 18.364 5.63593C18.7545 6.02646 18.7545 6.65962 18.364 7.05015C17.9734 7.44067 17.3403 7.44067 16.9497 7.05015Z"
//                               ></path>
//                             </svg>
//                         )}
//                         {profile.loading
//                             ? t("loading")
//                             : !is_forgetpass
//                                 ? t("login.Signin")
//                                 : t("login.senpass")}
//                       </button>
//                     </div>
//                   </form>
//                 </Dialog.Panel>
//               </Transition.Child>
//             </div>
//           </div>): <h2>Muse enable location to can use our service</h2>}
//         </Dialog>
//       </Transition>
//     </>
//   );
// }
