import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { IoLocationOutline } from "react-icons/io5";
import { TbArmchair2 } from "react-icons/tb";
import { BiTimeFive } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { BsCalendar4Event } from "react-icons/bs";
import { setFilters } from "src/store/slices/visaSlice";
import { BsChevronDoubleDown } from "react-icons/bs";
import { useTranslation } from "react-i18next";

const CardsLayoutV = () => {
  const { t } = useTranslation();

  const visa = useSelector((state) => state.visa.results);
  const navigate = useNavigate();
  const filters = useSelector((state) => state.visa.filters);
  const execute = useDispatch();

  return (
    <div className="flex flex-wrap gap-5">
      {!visa.loading ? (
        visa.data.map((v) => (
          <div
            key={v.id}
            className="flex overflow-hidden rounded-lg shadow-lg h-64 w-full"
          >
            <div className=" relative">
              <img
                src={v.image_main_url}
                className="object-cover w-full h-64 max-h-54"
                alt="groupimage"
              />
            </div>
            <div className="block  w-full">
              <div className="flex  flex-col  gap-2  h-full w-full  justify-around   px-5 bg-white ">
                <div className="flex items-center gap-2     font-semibold ">
                  <div className="flex border border-orange-600  w-auto px-2 h-8  rounded-full  items-center gap-2">
                    <IoLocationOutline className="text-orange-400" size={20} />
                    <p className="text-orange-600">{v.address}</p>
                  </div>
                  <div className="flex border border-orange-600  w-auto h-8  px-2 rounded-full   items-center gap-2">
                    <BiTimeFive className="text-orange-400" size={20} />
                    <p className="text-orange-600 ">
                      {v.days}
                      {t("visa.daystoissue")}
                    </p>
                  </div>
                </div>

                <div className="">
                  <p className="mt-2 text-2xl font-medium text-gray-800">
                    {v.name}
                  </p>
                  {/* <p className="text-lead text-gray-500">{v.desc}</p> */}
                </div>

                <div className="flex gap-4 justify-between">
                  <div className="flex gap-4 items-center">
                    {/* <div className="flex flex-col items-center text-sm  text-gray-400">
                      <span>Price in </span>
                      <span>{v.currency}</span>
                    </div> */}
                    <div className="flex items-center gap-2">
                      <span className="font-bold text-3xl text-orange-600">
                        {v.price}
                      </span>
                      <span className="text-gray-400 text-sm font-semibold">
                        {t("filters.ADT")}
                      </span>
                    </div>
                    <div className="w-0.8 h-12 border border-gray-300 "></div>
                    <div className="flex items-center gap-2">
                      <span className="font-bold text-3xl text-orange-600">
                        {v.child_price}
                      </span>
                      <span className="text-gray-400 text-sm font-semibold">
                        {t("filters.CHD")}
                      </span>
                    </div>
                    <div className="w-0.8 h-12 border border-gray-300 "></div>
                    <div className="flex items-center gap-2">
                      <span className="font-bold text-3xl text-orange-600">
                        {v.infant_price}
                      </span>
                      <span className="text-gray-400 text-sm font-semibold">
                        {t("filters.INF")}
                      </span>
                    </div>
                  </div>
                  <div>
                    <button
                      onClick={() => {
                        //using react-router-dom
                        navigate(`/visa/${v.id}`);
                        // window.location.href = `/groups/${group.id}`;
                      }}
                      className="bg-orange-600 text-white px-4 py-2 rounded-md"
                    >
                      {t("cardsinfo.deal")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))
      ) : (
        <div>Loading...</div>
      )}
      {!visa.loading && visa.total > 0 && filters.take < visa.total && (
        <div className="mx-auto">
          <button
            onClick={() => {
              // setCount(count + 5);
              execute(
                setFilters({
                  take: filters.take + 5,
                  skip: 0,
                })
              );
            }}
            className="flex gap-2 w-64 items-center justify-center  text-orange-600 border  border-orange-600  hover:shadow-md p-2  rounded-md"
          >
            <BsChevronDoubleDown />
            {t("loadmore")}
          </button>
        </div>
      )}
    </div>
  );
};

export default CardsLayoutV;
