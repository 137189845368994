import Swal from "sweetalert2";

const successAlert = (title) => {
    Swal.fire({
        position: "top-end",
        icon: "success",
        title: title,
        showConfirmButton: false,
        timer: 3000
    });
};



export default successAlert;
