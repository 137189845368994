import api from "../apiConfig";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    search(params) {
        // const { from, to, departure, Return, adt, chd, inf, Class } = params;
        return api.post(`/AAAF/search`, params);
    },
    fareRules(data) {
        return api.post("/AAAF/fare-rules", data);
    },
    airPrice(data) {
        return api.post(`/AAAF/air-price`, data);
    },
    book(data) {
        return api.post(`/AAAF/book`, data);
    },
    preSale(data) {
        return api.post(`/AAAF/air-presale`, data);
    },
};
