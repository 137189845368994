import i18next from "i18next";

import {initReactI18next} from "react-i18next";


// We can localize to any language and any number of languages.
const resources = {
    en: {
        translation: {
            loading: "Loading...",
            loadmore: "Load More",
            home: {
                global: "Flights",
                cardsBrif: "What Booking Travel brings to the table.",
                footer:
                    "Search cheap flights with Booking Travel. Search for the cheapest airline tickets for all the top airlines around the world and the top international flight routes. Booking Travel searches hundreds of travel sites to help you find cheap airfare and book a flight that suits you best. Since Booking Travel searches many plane tickets sites at once, you can find cheap tickets from cheap airlines quickly. Booking Travel also helps you find the right hotels for your needs. Not what you’re looking for? Find thousands of other hotels, flights with Booking Travel",
                ourpartners: "Our Partners",
                ourpartnersdesc:
                    "we are working with the best partners in the world to provide you with the best services.",
                subscribe: "Subscribe to our newsletter",
                subscribebtn: "Subscribe",
                subscribetext:
                    "Sign up for email updates with travel recommendations and Private Deals.",
                airlineworking: "Airlines we are working with",
                joinus: "Join us with Booking Travel Groups for your next adventure. ",
                jounusdesc:
                    "Days are getting longer and the urge to travel is getting stronger - use these guides to save this spring.",
                cardsinfo: "What Booking Travel brings to the table.",
                cardtitle1: "Big names, great deals",
                cardtitle1desc: "Search 100s of travel sites to compare prices",
                cardtitle2: "Book with flexibility",
                cardtitle2desc:
                    "Use our “no change fees” filter for flights that waive fees",

                cardtitle3: "Track prices",
                cardtitle3desc: "Not ready to book? Set alerts for when prices drop",

                cardtitle4: "More than flights",
                cardtitle4desc:
                    "Find deals for your entire trip from hotels to rental cars",
            },
            sidebar: {
                home: "Home",
                travelport: "Travelport GDS",
                iraqiairways: "Iraqi Airways",
                airarabia: "Air Arabia",
                flybgd: "Fly Baghdad",
                turkish: "Turkish Airlines",
                flydubai: "Fly Dubai Airlines",
                pegasus: "Pegasus Airlines",
                hotels: "Hotels",
                groups: "Groups",
                visa: "Visa",
                aboutus: "About Us",
                contactus: "Contact Us",
                faq: "FAQs",
                privacy: "Privacy Policy",
            },
            filters: {
                oneway: "One-Way",
                roundTrip: "Round Trip",
                multiCity: "Multi-City",
                Economy: "Economy",
                Business: "Business",
                First: "First Class",
                PremiumEconomy: "Premium Economy",
                travelers: "Travelers , {{travelers}}",
                ADT: "Adult",
                CNN: "Child",
                INF: "Infant",
                CHD: "Child",
                adt: "Adult",
                chd: "Child",
                inf: "Infant",
                from: "From",
                to: "To",
                Child: "Child",
                Adult: "Adult",
                Infant: "Infant",
            },
            cardsinfo: {
                direct: "Direct",
                stop: "Stop",
                deal: "View Deal",
                total: "Total",
                base: "Base",
                available: "Available",
            },
            tabsinfo: {
                tripdetails: "Trip Details",
                price: "Price",
                fare: "Fare Rules",
                book: "Book",
                flyingtime: "Flying Time  : ",
                depart: "Depart",
                arrival: "Arrival",
                groundtime: "Ground Time",
                airline: "Airline",
                flight: "Flight",
                class: "Class",
                seats: "Seats",
                back: "Back",
                return: "Return",
            },
            pricetab: {
                priceinfo: "Price Info",
                fareprice: "Fare Pricing : ",
                PlatingCarriers: "Plating Carriers : ",
                PassengerFares: "Passenger Fares",
                FareInfo: "Fare Info",
                latesttickettime: "Latest Ticketing Time : ",
                Refundable: "Refundable : ",
                ETicketability: "E-Ticketability : ",
                CancelPenalty: "Cancel Penalty : ",
                ChangePenalty: "Change Penalty : ",
                BaggageInfo: "Baggage Info",
                TaxesInfo: "Taxes Info",
                taxes: "Taxes",
                value: "Value",
                type: "Type",
            },
            faretab: {
                rulenumber: "Rule Number : ",
                source: "Source : ",
                VoluntaryChanges: "Voluntary Changes : ",
                VoluntaryRefunds: "Voluntary Refunds : ",
                DepartureStatus: "Departure Status : ",
                Amount: "Amount : ",
                PenaltyType: "Penalty Type : ",
            },
            book: {
                surname: "Surname",
                fname: "First Name",
                mname: "Middle Name",
                lname: "Last Name",
                email: "Email",
                gender: "Gender",
                nationality: "Nationality",
                Resident: "Country Resident",
                passportnumber: "Passport Number",
                passportexpiry: "Passport Expiry Date",
                birthdate: "Birth Date",
                countrycode: "Country Code",
                phoneNumber: "Phone Number",
                Maininformation: "Main Information",
                PassportInformation: "Passport Information",
                selectattachment: "Select Attachments",
                attachmenttip: "Attachments to Upload",
                booknow: "Book Now",
                PassportIssueCountry: "Passport Issue Country",
                PassportHolderNationality: "Passport Holder Nationality",
                ContactInformation: "Contact Information",
                BookingType: "Booking Type",
                preBooking: "Pre Booking",
                booking: "Booking",
                mr: "Mr.",
                ms: "Ms.",
                miss: "Miss.",
                mst: "Mst.",
                male: "Male",
                female: "FeMale",
            },
            errors: {
                chd: "Children must be greater than 2 years old",
                adt: "Adults must be greater than 12 years old",
                inf: "Infants must be less than 2 years old",
                firstname: "First name and last name must be filled",
                passportExpier: "Passport Expire must be greater than 6 months",
                passportNo: "Passport number must be filled",
            },
            contactus: {
                contactus: "Contact Us Form",
                name: "Name",
                message: "Message",
                send: "Send Message",
            },
            visa: {
                PassengerType: "Passenger Type",
                daystoissue: "Days to issue",
                viewdetails: "View Details",
                pricein: "Price in",
                usd: "USD",
                visarequirements: "Visa Requirements",
                addanothertraveler: "Add Another Traveler",
                search: "Search",
                all: "All",
                country: "Country",
                pricehigh: "Price-Highest",
                pricelow: "Price-Lowest",
                namedesc: "Name-Descending",
                nameasc: "Name-Ascending",
                ratehigh: "Rate-Highest",
                ratelow: "Rate-Lowest",
                datenew: "Date-Newest",
                dateold: "Date-Oldest",
                daysnew: "Days-Newest",
                daysold: "Days-Oldest",
            },
            groups: {
                adtsingleroom: "Adults with Single Room",
                Days: "Days",
                start: "Start",
                end: "End",
                SGL: "Single Room",
                inc: "Included",
                tvltime: "Travel Timeline",
            },
            hotels: {
                checkin: "Check In Date",
                checkout: "Check Out Date",
                cinout: "Check In/Out",
                city: "City",
                hname: "Hotel Name",
                stars: "Select Stars",
                star: "Star {{star}}",
                starinfo: "you can select multiple stars by clicking on the star icon",
                mdetails: "More Details",
                map: "Map",
                selecttravlers: "Select Travelers",
                hoteldesc: "Hotel Description",
                images: "Images",
                offers: "Offers",
                selectedroom: "Selected Room",
                showroomdetails: "Show Room Details",
                overview: "Overview",
                RoomsOffers: "Rooms & Offers : ",
                Offers: "Offers",
                prebooking: "Pre Booking Now",
                info: "Info",
                code: "Code",
                success: "Success You are Pre Booked This Room",
                bookinginfo: " Go To The Book Tab to complete the Book process !",
                from: "From",
                to: "To",
                for: "For",
                autocanceldate: "Auto Cancel Date",
                ServiceDates: "Service Dates",
                CancellationPolicy: "Cancellation Policy",
                remarks: "Remarks",
            },
            profile: {
                profile: "Profile",
                profileinfo:
                    "Booking history, Transactions and status of your account",
                balance: "Balance in , {{currency}}",
                TotalBooking: "Total Booking",
                logout: "Logout",
                signout: "Sign Out of your account",
                transactions: "Transactions",
                bookinghistory: "Booking History",
                adddeposit: "Add Deposit",
                settings: "Settings",
                action: "Action",
                BookingAction: "Booking Action",
                sendaction: "Send Action",
                resetpassword: "Reset Password",
                reset: "Reset",
                newpassword: "New Password",
                currentpassword: "Current Password",
                confirmpassword: "Confirm Password",
                changePassword: "Change Password",
            },
            zain: {
                AddDeposit: "Add Deposit",
                title: "ZainCash Payment Gateway",
                subtitle:
                    "The minimum amount is 1 USD and the maximum amount is 5000 USD",
                subtitle2:
                    "You will be redirected to ZainCash website to complete the payment",
                paywith: "Pay With ZainCash",
                error1: "The minimum amount is 1 USD",
                error2: "The maximum amount is 5000 USD",
                redierct: "redirected to home page in 5 seconds...",
                success: "Payment Successful",
            },
            login: {
                Signin: "Sign in",
                Password: "Password",
                Passwordhint: "please enter your Password",
                forgotpassword: "Forgot Password ?",
                senpass: "Send me a new password",
                msgcontactus:
                    " Contact with us if you have any problem through chat below!",
                msgcontactus2:
                    " Once you enter your email, we will send you a new , temporary password, change it later in your profile",
                gologin: "Go to Login",
            },
            messages: {
                resetpasswordemail: "Message Sent Successfully, Check your email !",
                resetpasswordsuccss: "Password Changed Successfully ",
                error: "Something went wrong",
            },
            turkish: {
                flights: "Flights",
            },
            arabia: {
                Bundles: "Bundles",
                Baggage: "Baggage",
                Meal: "Meal",
                Seats: "Seats",
                FullPrice: "Full Price",
                Book: "Book",
                selectFlightSeat: "Select Flight Seat",
                seatByFlight: "Selected Seats By Flight",
                inbookflex: "In Bound Flexi Available ?",
                outbond: "Out Bound Flexi Available ?",
                noinbound: "No In Bound Flexi Available",
                flexidesc: "Flexibility Description :",
                nodesc: "No Description Available",
                allowedFlexi: "Allowed Flexi Operations :",
                allowedOperations: "Allowed Operation Name",
                flexiOperations: "Flexi Operation Cutover Time In Minutes :",
                noofallowed: "Number Of Allowed Operations :",
                noofallowed2: "No Out Bound Flexi Available",
                noprice: "No Price Available",
            },
            global: {
                sort: "Sort",
                filters: "Filters",
                applay: "Apply Sort & Filters",
            },
        },
    },
    ar: {
        translation: {
            loading: "جاري التحميل ...",
            loadmore: "تحميل المزيد",
            home: {
                global: "الرحلات ",
                cardsBrif: "What Booking Travel brings to the table.",
                footer:
                    "إبحث عن رحلات طيران رخيصة مع المتين. ابحث عن أرخص تذاكر الطيران لجميع شركات الطيران الكبرى حول العالم وأفضل مسارات الطيران الدولية. يبحث المتين في مئات مواقع السفر لمساعدتك في العثور على تذكرة طيران رخيصة وحجز الرحلة التي تناسبك. بما أن المتين يقوم بالبحث في العديد من مواقع تذاكر الطيران في وقت واحد، يمكنك العثور على تذاكر رخيصة من شركات الطيران الرخيصة بسرعة. كما يساعدك المتين في العثور على الفنادق المناسبة لاحتياجاتك. ليس ما تبحث عنه؟ يمكنك العثور على آلاف الفنادق ورحلات الطيران الأخرى مع المتين",
                ourpartners: "Our Partners",
                ourpartnersdesc:
                    "نحن نعمل مع أفضل الشركاء في العالم لنقدم لك أفضل الخدمات.",
                subscribe: "اشترك في النشرة الإخبارية لدينا",
                subscribebtn: "اشتراك",
                subscribetext:
                    "قم بالتسجيل للحصول على تحديثات البريد الإلكتروني مع توصيات السفر والعروض الخاصة.",
                airlineworking: "الخطوط الجوية التي نعمل معها",
                joinus: "انضم إلينا مع مجموعة المتين لمغامرتك القادمة. ",
                jounusdesc: "غامر معنا في رحلة لا تنسى",
                cardsinfo: "مالذي يقدمه المتين",
                cardtitle1: "أسماء كبيرة ، صفقات رائعة",
                cardtitle1desc: "ابحث في مئات مواقع السفر لمقارنة الأسعار",
                cardtitle2: "احجز بمرونة",
                cardtitle2desc:
                    "استخدم مرشحنا 'بدون رسوم تغيير' للرحلات التي تتخلص من الرسوم",
                cardtitle3: "تتبع الأسعار",
                cardtitle3desc: "لست مستعدًا للحجز؟ قم بتعيين تنبيهات لتنخفض الأسعار",
                cardtitle4: "أكثر من رحلات طيران",
                cardtitle4desc:
                    "ابحث عن صفقات لرحلتك بأكملها من الفنادق إلى سيارات الأجرة",
            },
            sidebar: {
                home: "الرئيسية",
                travelport: "ترافل بورت",
                iraqiairways: "طيران العراق",
                airarabia: "طيران العربية",
                flybgd: "طيران فلاي بغداد",
                turkish: "طيران التركية",
                flydubai: "طيران فلاي دبي",
                pegasus: "طيران بيجاسوس",
                hotels: "الفنادق",
                groups: "الكروبات",
                visa: "فيزا",
                aboutus: "عنا",
                contactus: "أتصل بنا",
                faq: "الأسئلة الشائعة",
                privacy: "سياسة الخصوصية",
            },
            filters: {
                oneway: "ذهاب فقط",
                roundTrip: "ذهاب-عودة",
                multiCity: "متعددة المدن",
                Economy: "اقتصادي",
                Business: "رجال الأعمال",
                First: "درجة أولى",
                PremiumEconomy: "اقتصادي ممتاز",
                travelers: "المسافرين , {{travelers}}",
                adt: "البالغين",
                ADT: "بالغ",
                CNN: "طفل",
                INF: "رضيع",
                CHD: "طفل",
                chd: "الأطفال",
                inf: "الرضع",
                from: "من",
                to: "الى",
                Child: "طفل",
                Adult: "بالغ",
                Infant: "رضيع",
            },
            cardsinfo: {
                direct: "مباشر",
                stop: "توقف",
                deal: "عرض",
                total: "المجموع",
                base: "الأساسي",
                available: "متاح",
            },
            tabsinfo: {
                tripdetails: "تفاصيل الرحلة",
                price: "تفاصيل السعر",
                fare: "قواعد السعر",
                book: "الحجز",
                flyingtime: "وقت الرحلة  : ",
                depart: "المغادرة",
                arrival: "الوصول",
                groundtime: "التوقيت الأرضي",
                airline: "الخطوط الجوية",
                flight: "رقم الرحلة",
                class: "الدرجة",
                seats: "المقاعد",
                back: "رجوع",
                return: "العودة",
            },
            pricetab: {
                priceinfo: "تفاصيل السعر",
                fareprice: "تفاصيل قواعد السعر : ",
                PlatingCarriers: " شركات الطيران : ",
                PassengerFares: "أسعار الركاب",
                FareInfo: " قواعد السعر : ",
                latesttickettime: "اخر وقت لقطع التئكرة : ",
                Refundable: "هل يمكن استردادها ؟ : ",
                ETicketability: "هل تذكرة الكترونية : ",
                CancelPenalty: "عقوبة الالغاء : ",
                ChangePenalty: "عقوبة تغيير التذكرة : ",
                BaggageInfo: "معلومات الأمتعة",
                TaxesInfo: "معلومات الضرائب",
                taxes: "الضرائب",
                value: "القيمة",
                type: "النوع",
            },
            faretab: {
                rulenumber: "رقم الشرط : ",
                source: "المصدر : ",
                VoluntaryChanges: "التغيرات الطوعية : ",
                VoluntaryRefunds: "المبالغ المستردة الطوعية : ",
                DepartureStatus: "حالة المغادرة : ",
                Amount: "الأجمالي : ",
                PenaltyType: "نوع الجزاء: ",
            },
            book: {
                surname: "اللقب",
                fname: "الاسم الاول",
                mname: "الاسم الاب",
                lname: "اسم الجد",
                email: "البريد الالكتروني",
                gender: "الجنس",
                nationality: "الجنسية",
                Resident: "الاقامة",
                passportnumber: "رقم جواز السفر",
                passportexpiry: "تاريخ انتهاء جواز السفر",
                birthdate: "تاريخ الميلاد",
                countrycode: "مفتاح الدولة",
                phoneNumber: "رقم الهاتف",
                Maininformation: "المعلومات الرئيسية",
                PassportInformation: "معلومات جواز السفر",
                selectattachment: "اختر المرفقات",
                attachmenttip: "المرفقات المرفقة",
                booknow: "احجز الان",
                PassportIssueCountry: "بلد اصدار جواز السفر",
                PassportHolderNationality: "جنسية حامل جواز السفر",
                ContactInformation: "معلومات الاتصال",
                BookingType: "نوع الحجز",
                preBooking: "حجز مسبق",
                booking: "حجز",
                mr: "سيد.",
                ms: "سيدة.",
                miss: "آنسة.",
                mst: "Mst.",
                male: "ذكر",
                female: "انثى",
            },
            errors: {
                chd: "يجب أن يكون عمر الأطفال أكبر من عامين",
                adt: "يجب أن يكون عمر البالغين أكبر من 12 عامًا",
                inf: "يجب أن يكون عمر الرضع أقل من عامين",
                firstname: "يجب ملء الاسم الأول واسم العائلة",
                passportExpier: "يجب أن تكون مدة صلاحية جواز السفر أكثر من 6 أشهر",
                passportNo: "يجب ملء رقم جواز السفر",
            },
            contactus: {
                contactus: "بيانات الاتصال بنا",
                name: "الاسم",
                message: "محتوى الرسالة",
                send: "ارسال الرسالة",
            },
            visa: {
                PassengerType: "نوع المسافر",
                daystoissue: "أيام للإصدار",
                viewdetails: "التفاصيل",
                pricein: "السعر بـ",
                usd: "دولار أمريكي",
                visarequirements: "متطلبات الفيزا",
                addanothertraveler: "اضف مسافر اخر",
                search: "بحث",
                all: "الكل",
                country: "الدولة",
                pricehigh: "السعر-الأعلى",
                pricelow: "السعر-الأقل",
                namedesc: "الاسم-تنازلي",
                nameasc: "الاسم-تصاعدي",
                ratehigh: "التقييم-الأعلى",
                ratelow: "التقييم-الأقل",
                datenew: "التاريخ-الأحدث",
                dateold: "التاريخ-الأقدم",
                daysnew: "الأيام-الأحدث",
                daysold: "الأيام-الأقدم",
            },
            groups: {
                adtsingleroom: "البالغين مع غرفة واحدة",
                Days: "أيام",
                start: "البداية",
                end: "النهاية",
                SGL: "غرفة واحدة",
                inc: "شامل",
                tvltime: "المدة الزمنية للرحلة",
            },
            hotels: {
                checkin: "تاريخ الوصول",
                checkout: "تاريخ المغادرة",
                cinout: "",
                city: "المدينة",
                hname: "اسم الفندق",
                stars: "اختر التقييم",
                star: "نجمة {{star}}",
                starinfo: "يمكنك اختيار عدة نجوم عن طريق النقر على رمز النجمة",
                mdetails: "المزيد من التفاصيل",
                map: "الخريطة",
                selecttravlers: "اختر المسافرين",
                hoteldesc: "وصف الفندق",
                images: "الصور",
                offers: "العروض",
                selectedroom: "الغرفة المختارة",
                showroomdetails: "عرض تفاصيل الغرفة",
                overview: "نظرة عامة",
                RoomsOffers: "عروض الغرف : ",
                Offers: "العروض",
                prebooking: "حجز مسبق الان",
                info: "معلومات",
                code: "الرمز",
                success: "تم حجز الغرفة بنجاح",
                bookinginfo: "اذهب الى صفحة الحجز لاستكمال عملية الحجز",
                from: "من",
                to: "الى",
                for: "لمدة",
                autocanceldate: "تاريخ الالغاء التلقائي",
                ServiceDates: "تواريخ الخدمة",
                CancellationPolicy: "سياسة الالغاء",
                remarks: "ملاحظات",
            },
            profile: {
                profile: "الملف الشخصي",
                profileinfo: "سجل الحجوزات والمعاملات وحالة حسابك",
                balance: "الرصيد ب , {{currency}}",
                TotalBooking: "مجموع الحجوزات",
                logout: "تسجيل الخروج",
                signout: "تسجيل الخروج من حسابك",
                transactions: "المعاملات",
                bookinghistory: "سجل الحجوزات",
                adddeposit: "اضافة رصيد",
                settings: "الاعدادات",
                action: "الاجراءات",
                BookingAction: "اجراءات الحجز",
                sendaction: "ارسال الاجراء",
                resetpassword: "اعادة تعيين كلمة المرور",
                reset: "اعادة تعيين",
                newpassword: "كلمة المرور الجديدة",
                currentpassword: "كلمة المرور الحالية",
                confirmpassword: "تأكيد كلمة المرور",
                changePassword: "تغيير كلمة المرور",
            },
            zain: {
                AddDeposit: "اضافة رصيد",
                title: "بوابة الدفع زين كاش",
                subtitle: "الحد الأدنى للمبلغ هو 1 دولار والحد الأقصى هو 5000 دولار",
                subtitle2: "سيتم توجيهك إلى موقع زين كاش لإكمال عملية الدفع",
                paywith: "ادفع بواسطة زين كاش",
                error1: "الحد الأدنى للمبلغ هو 1 دولار",
                error2: "الحد الأقصى للمبلغ هو 5000 دولار",
                redierct: "سيتم توجيهك إلى الصفحة الرئيسية في 5 ثوانٍ ...",
                success: "تم الدفع بنجاح",
            },
            login: {
                Signin: "تسجيل الدخول",
                Password: "كلمة المرور",
                Passwordhint: "الرجاء إدخال كلمة المرور",
                forgotpassword: "نسيت كلمة المرور ؟",
                senpass: "أرسل لي كلمة مرور جديدة",
                msgcontactus: " اتصل بنا إذا كان لديك أي مشكلة من خلال الدردشة أدناه!",
                msgcontactus2:
                    " بمجرد إدخال بريدك الإلكتروني ، سنرسل لك كلمة مرور جديدة مؤقتة ، وقم بتغييرها لاحقًا في ملفك الشخصي",
                gologin: "الذهاب إلى تسجيل الدخول",
            },
            messages: {
                resetpasswordemail:
                    "تم إرسال الرسالة بنجاح ، تحقق من بريدك الإلكتروني!",
                resetpasswordsuccss: "تم تغيير كلمة المرور بنجاح",
                error: "حدث خطأ ما",
            },
            turkish: {
                flights: "الرحلات",
            },
            arabia: {
                Bundles: "حزم",
                Baggage: "الأمتعة",
                Meal: "الوجبات",
                Seats: "المقاعد",
                FullPrice: "السعر الكامل",
                Book: "احجز",
                selectFlightSeat: "اختر مقعد الرحلة",
                seatByFlight: "المقاعد المختارة حسب الرحلة",
                inbookflex: "In Bound Flexi Available ?",
                outbond: "Out Bound Flexi Available ?",
                noinbound: "No In Bound Flexi Available",
                flexidesc: "وصف المرونة:",
                nodesc: "لا يوجد وصف متاح",
                allowedFlexi: "Allowed Flexi Operations :",
                allowedOperations: "اسم العملية المسموح بها",
                flexiOperations: "وقت قطع العملية المرنة بالدقائق:",
                noofallowed: "عدد العمليات المسموح بها :",
                noofallowed2: "No Out Bound Flexi Available",
                noprice: "لايوجد سعر متاح",
            },
            global: {
                sort: "ترتيب",
                filters: "فلاتر",
                applay: "تطبيق الترتيب والفلاتر",
            },
        },
    },
    tr: {
        translation: {
            "loading": "Yükleniyor...",
            "loadmore": "Daha Fazla Yükle",
            "home": {
                "global": "Küresel Uçuşlar",
                "cardsBrif": "Booking Travel'in masasına koyduğu şey nedir.",
                "footer": "Booking Travel ile ucuz uçuşları arayın. Dünya genelindeki tüm önde gelen havayolu şirketleri ve en önemli uluslararası uçuş rotaları için en ucuz uçak biletlerini arayın. Booking Travel, size uygun olan ucuz uçak bileti bulmanıza yardımcı olmak için yüzlerce seyahat sitesini tarar. Booking Travel, birçok uçak bileti sitesini aynı anda taradığından, ucuz biletleri hızlı bir şekilde bulabilirsiniz. Booking Travel ayrıca ihtiyaçlarınıza uygun otelleri bulmanıza yardımcı olur. Aradığınız şey bu değil mi? Booking Travel ile binlerce diğer otel ve uçuşu bulun.",
                "ourpartners": "İş Ortaklarımız",
                "ourpartnersdesc": "Dünyanın en iyi ortakları ile çalışıyoruz, size en iyi hizmetleri sunmak için.",
                "subscribe": "Bültenimize Abone Ol",
                "subscribebtn": "Abone Ol",
                "subscribetext": "Seyahat önerileri ve Özel Fırsatlar ile güncellemeler için e-posta aboneliği yapın.",
                "airlineworking": "Çalıştığımız Havayolları",
                "joinus": "Bir sonraki maceranız için Booking Travel Grupları'na katılın.",
                "jounusdesc": "Günler uzuyor ve seyahat etme isteği güçleniyor - bu rehberleri kullanarak bu baharı kurtarın.",
                "cardsinfo": "Booking Travel'in masasına koyduğu şey nedir.",
                "cardtitle1": "Büyük isimler, harika fırsatlar",
                "cardtitle1desc": "Fiyatları karşılaştırmak için yüzlerce seyahat sitesini arayın",
                "cardtitle2": "Esneklikle rezervasyon yapın",
                "cardtitle2desc": "Ücretleri feragat eden uçuşlar için “değişiklik ücretleri yok” filtresini kullanın",
                "cardtitle3": "Fiyatları takip edin",
                "cardtitle3desc": "Hazır değil misiniz? Fiyatlar düştüğünde bildirimler ayarlayın",
                "cardtitle4": "Sadece uçuşlar değil",
                "cardtitle4desc": "Otellerden kiralık arabalara kadar tüm geziniz için fırsatlar bulun",
            },
            "sidebar": {
                "home": "Ana Sayfa",
                "travelport": "Travelport GDS",
                "iraqiairways": "Irak Hava Yolları",
                "airarabia": "Air Arabia",
                "flybgd": "Fly Baghdad",
                "turkish": "Türk Hava Yolları",
                "flydubai": "Fly Dubai Havayolları",
                "pegasus": "Pegasus Havayolları",
                "hotels": "Oteller",
                "groups": "Gruplar",
                "visa": "Vize",
                "aboutus": "Hakkımızda",
                "contactus": "Bize Ulaşın",
                "faq": "SSS",
                "privacy": "Gizlilik Politikası",
            },
            "filters": {
                "oneway": "Tek Yön",
                "roundTrip": "Gidiş Dönüş",
                "multiCity": "Çoklu Şehir",
                "Economy": "Ekonomi",
                "Business": "İş",
                "First": "First Class",
                "PremiumEconomy": "Premium Ekonomi",
                "travelers": "Yolcular, {{travelers}}",
                "ADT": "Yetişkin",
                "CNN": "Çocuk",
                "INF": "Bebek",
                "CHD": "Çocuk",
                "adt": "Yetişkin",
                "chd": "Çocuk",
                "inf": "Bebek",
                "from": "Nereden",
                "to": "Nereye",
                "Child": "Çocuk",
                "Adult": "Yetişkin",
                "Infant": "Bebek",
            },
            "cardsinfo": {
                "direct": "Doğrudan",
                "stop": "Durak",
                "deal": "Fırsatı Görüntüle",
                "total": "Toplam",
                "base": "Taban",
                "available": "Kullanılabilir",
            },
            "tabsinfo": {
                "tripdetails": "Seyahat Detayları",
                "price": "Fiyat",
                "fare": "Ücret Kuralları",
                "book": "Rezervasyon Yap",
                "flyingtime": "Uçuş Süresi : ",
                "depart": "Kalkış",
                "arrival": "Varış",
                "groundtime": "Yer Hizmetleri Süresi",
                "airline": "Havayolu",
                "flight": "Uçuş",
                "class": "Sınıf",
                "seats": "Koltuklar",
                "back": "Geri",
                "return": "Dönüş",
            },
            "pricetab": {
                "priceinfo": "Fiyat Bilgisi",
                "fareprice": "Ücretlendirme : ",
                "PlatingCarriers": "Plating Taşıyıcıları : ",
                "PassengerFares": "Yolcu Ücretleri",
                "FareInfo": "Ücret Bilgisi",
                "latesttickettime": "En Son Bilet Zamanı : ",
                "Refundable": "İade Edilebilir : ",
                "ETicketability": "E-Bilet Kullanılabilirliği : ",
                "CancelPenalty": "İptal Cezası : ",
                "ChangePenalty": "Değişiklik Cezası : ",
                "BaggageInfo": "Bagaj Bilgisi",
                "TaxesInfo": "Vergi Bilgisi",
                "taxes": "Vergiler",
                "value": "Değer",
                "type": "Tür",
            },
            "faretab": {
                "rulenumber": "Kural Numarası : ",
                "source": "Kaynak : ",
                "VoluntaryChanges": "Gönüllü Değişiklikler : ",
                "VoluntaryRefunds": "Gönüllü İadeler : ",
                "DepartureStatus": "Kalkış Durumu : ",
                "Amount": "Tutar : ",
                "PenaltyType": "Cezai Tür : ",
            },
            "book": {
                "surname": "Soyadı",
                "fname": "Adı",
                "mname": "İkinci Adı",
                "lname": "Soyadı",
                "email": "E-posta",
                "gender": "Cinsiyet",
                "nationality": "Uyruk",
                "Resident": "Ülke Sakini",
                "passportnumber": "Pasaport Numarası",
                "passportexpiry": "Pasaportun Geçerlilik Tarihi",
                "birthdate": "Doğum Tarihi",
                "countrycode": "Ülke Kodu",
                "phoneNumber": "Telefon Numarası",
                "Maininformation": "Ana Bilgiler",
                "PassportInformation": "Pasaport Bilgileri",
                "selectattachment": "Eklentileri Seç",
                "attachmenttip": "Yüklemek İçin Ekler",
                "booknow": "Şimdi Rezervasyon Yap",
                "PassportIssueCountry": "Pasaport Veriliş Ülkesi",
                "PassportHolderNationality": "Pasaport Sahibinin Uyruğu",
                "ContactInformation": "İletişim Bilgileri",
                "BookingType": "Rezervasyon Türü",
                "preBooking": "Ön Rezervasyon",
                "booking": "Rezervasyon",
                "mr": "Bay",
                "ms": "Bayan",
                "miss": "Bayan",
                "mst": "Bayan",
                "male": "Erkek",
                "female": "Kadın",
            },
            "errors": {
                "chd": "Çocuklar 2 yaşından büyük olmalıdır",
                "adt": "Yetişkinler 12 yaşından büyük olmalıdır",
                "inf": "Bebekler 2 yaşından küçük olmalıdır",
                "firstname": "Ad ve soyadı doldurulmalıdır",
                "passportExpier": "Pasaportun süresi 6 aydan fazla olmalıdır",
                "passportNo": "Pasaport numarası doldurulmalıdır",
            },
            "contactus": {
                "contactus": "Bize Ulaşın Formu",
                "name": "Ad",
                "message": "Mesaj",
                "send": "Mesaj Gönder",
            },
            "visa": {
                "PassengerType": "Yolcu Türü",
                "daystoissue": "İşlem Günü",
                "viewdetails": "Detayları Görüntüle",
                "pricein": "Fiyat",
                "usd": "USD",
                "visarequirements": "Vize Gereksinimleri",
                "addanothertraveler": "Başka Bir Yolcu Ekle",
                "search": "Ara",
                "all": "Tümü",
                "country": "Ülke",
                "pricehigh": "Fiyat-En Yüksek",
                "pricelow": "Fiyat-En Düşük",
                "namedesc": "Ad-Azalan",
                "nameasc": "Ad-Artan",
                "ratehigh": "Puan-En Yüksek",
                "ratelow": "Puan-En Düşük",
                "datenew": "Tarih-En Yeni",
                "dateold": "Tarih-En Eski",
                "daysnew": "Günler-En Yeni",
                "daysold": "Günler-En Eski",
            },
            "groups": {
                "adtsingleroom": "Tek Kişilik Oda ile Yetişkinler",
                "Days": "Günler",
                "start": "Başlangıç",
                "end": "Bitiş",
                "SGL": "Tek Kişilik Oda",
                "inc": "Dahil",
                "tvltime": "Seyahat Zaman Çizelgesi",
            },
            "hotels": {
                "checkin": "Giriş Tarihi",
                "checkout": "Çıkış Tarihi",
                "cinout": "Giriş/Çıkış",
                "city": "Şehir",
                "hname": "Otel Adı",
                "stars": "Yıldızları Seç",
                "star": "{{star}} Yıldız",
                "starinfo": "Yıldız simgesine tıklayarak birden fazla yıldız seçebilirsiniz",
                "mdetails": "Daha Fazla Detay",
                "map": "Harita",
                "selecttravlers": "Yolcuları Seç",
                "hoteldesc": "Otel Açıklaması",
                "images": "Resimler",
                "offers": "Teklifler",
                "selectedroom": "Seçilen Oda",
                "showroomdetails": "Oda Detaylarını Göster",
                "overview": "Genel Bakış",
                "RoomsOffers": "Odalar ve Teklifler : ",
                "Offers": "Teklifler",
                "prebooking": "Ön Rezervasyon Şimdi",
                "info": "Bilgi",
                "code": "Kod",
                "success": "Başarılı! Bu Odayı Ön Rezerve Ettiniz",
                "bookinginfo": "Kitap sürecini tamamlamak için Kitap sekmesine gidin!",
                "from": "Nereden",
                "to": "Nereye",
                "for": "İçin",
                "autocanceldate": "Otomatik İptal Tarihi",
                "ServiceDates": "Hizmet Tarihleri",
                "CancellationPolicy": "İptal Politikası",
                "remarks": "Açıklamalar",
            },
            "profile": {
                "profile": "Profil",
                "profileinfo": "Rezervasyon geçmişi ve işlemler, hesap durumu",
                "balance": "Bakiye , {{currency}}",
                "TotalBooking": "Toplam Rezervasyon",
                "logout": "Çıkış Yap",
                "signout": "Hesabınızdan Çıkış Yap",
                "transactions": "İşlemler",
                "bookinghistory": "Rezervasyon Geçmişi",
                "adddeposit": "Depozito Ekle",
                "settings": "Ayarlar",
                "action": "Aksiyon",
                "BookingAction": "Rezervasyon Aksiyonu",
                "sendaction": "Aksiyon Gönder",
                "resetpassword": "Şifreyi Sıfırla",
                "reset": "Sıfırla",
                "newpassword": "Yeni Şifre",
                "currentpassword": "Mevcut Şifre",
                "confirmpassword": "Şifreyi Onayla",
                "changePassword": "Şifreyi Değiştir"
            },
            "zain": {
                "AddDeposit": "Depozito Ekle",
                "title": "ZainCash Ödeme Kapısı",
                "subtitle": "Minimum miktar 1 USD ve maksimum miktar 5000 USD",
                "subtitle2": "Ödemeyi tamamlamak için ZainCash web sitesine yönlendirileceksiniz",
                "paywith": "ZainCash ile Öde",
                "error1": "Minimum miktar 1 USD",
                "error2": "Maksimum miktar 5000 USD",
                "redierct": "5 saniye içinde ana sayfaya yönlendiriliyor...",
                "success": "Ödeme Başarılı"
            },
            "login": {
                "Signin": "Giriş Yap",
                "Password": "Şifre",
                "Passwordhint": "Lütfen şifrenizi girin",
                "forgotpassword": "Şifremi Unuttum ?",
                "senpass": "Bana yeni bir şifre gönder",
                "msgcontactus": "Aşağıdaki sohbet aracılığıyla herhangi bir sorunuz varsa bize ulaşın!",
                "msgcontactus2": "E-posta adresinizi girdikten sonra size yeni, geçici bir şifre göndereceğiz, daha sonra profilinizde değiştirebilirsiniz",
                "gologin": "Girişe Git"
            },
            "messages": {
                "resetpasswordemail": "Mesaj Başarıyla Gönderildi, E-postanızı kontrol edin!",
                "resetpasswordsuccss": "Şifre Başarıyla Değiştirildi",
                "error": "Bir şeyler ters gitti"
            },
            "turkish": {
                "flights": "Uçuşlar"
            },
            "arabia": {
                "Bundles": "Paketler",
                "Baggage": "Bagaj",
                "Meal": "Yemek",
                "Seats": "Koltuklar",
                "FullPrice": "Tam Fiyat",
                "selectFlightSeat": "Uçuş Koltuğu Seç",
                "seatByFlight": "Uçuşa Göre Seçilen Koltuklar",
                "inbookflex": "Girişte Flexi Var mı?",
                "outbond": "Çıkışta Flexi Var mı?",
                "noinbound": "Girişte Flexi Yok",
                "flexidesc": "Esneklik Açıklaması:",
                "nodesc": "Açıklama Yok",
                "allowedFlexi": "İzin Verilen Flexi İşlemleri:",
                "allowedOperations": "İzin Verilen İşlem Adı",
                "flexiOperations": "Flexi İşlem Geçiş Süresi (Dakika):",
                "noofallowed": "İzin Verilen İşlem Sayısı:",
                "noofallowed2": "Çıkışta Flexi Yok",
                "noprice": "Fiyat Yok"
            },
            "global": {
                "sort": "Sırala",
                "filters": "Filtreler",
                "applay": "Sırala ve Filtreleri Uygula"
            }
        }
    },
};

i18next.use(initReactI18next).init({
    resources,
    lng: localStorage.getItem("lang") || "en",
    interpolation: {
        escapeValue: false,
    },
    supportedLngs: ["en", "ar", "tr"],
    fallbackLng: "en",
});

export default i18next;
