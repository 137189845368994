import {createSlice} from "@reduxjs/toolkit";
import toast from "react-hot-toast";

import {factory} from "../../api/apiFactory";

const visaApi = factory.get("visa");
const othersApi = factory.get("others");

const initialState = {
    filters: {
        take: 5,
        skip: 0,
        sort: "",
        search: "",
        country_id: "",
    },
    results: {
        loading: false,
        status: false,
        message: "",
        description: "",
        total: 0,
        data: [],
    },
    visa: {
        loading: false,
        status: false,
        message: "",
        description: "",
        data: {
            id: "",
            address: "",
            name: "",
            rate: null,
            desc: "",
            price: "",
            child_price: "",
            infant_price: "",
            days: "",
            image_main_url: "",
            visa_needs: [],
        },
    },
    book: {
        loading: false,
        status: false,
        message: "",
        description: "",
    },
    country: {
        loading: false,
        status: false,
        message: "",
        description: "",
        data: [],
    },
    errors: "",
};

export const visaSlice = createSlice({
    name: "visa",
    initialState,
    reducers: {
        setResults(state, {payload}) {
            state.results = {...payload};
            state.results.loading = false;
        },
        setVisaErrors(state, {payload}) {
            state.errors = payload;
        },
        setVisa(state, {payload}) {
            state.visa.data = {...payload.data};
            state.visa.loading = false;
        },
        setLoading: (state, {payload}) => {
            state.results.loading = !state.results.loading;
        },
        setFilters: (state, {payload}) => {
            state.filters = {
                ...state.filters,
                ...payload,
            };
        },
        resetFilters: (state) => {
            state.filters = initialState.filters;
        },
        setBookLoading: (state, {payload}) => {
            state.book.loading = !state.book.loading;
        },
        getCountries(state, {payload}) {
            state.country = {...payload};
            state.country.loading = false;
        },
    },
});

export const {
    setLoading,
    setResults,
    setVisa,
    setVisaErrors,
    setFilters,
    resetFilters,
    setBookLoading,
    getCountries,
} = visaSlice.actions;

export default visaSlice.reducer;

//axios
const getData = () => async (dispatch, getState) => {
    try {
        const filters = getState().visa.filters;
        dispatch(setLoading("results"));
        const res = await visaApi.getVisas(filters);
        dispatch(setResults(res.data));
    } catch (err) {
        dispatch(setLoading("results"));
        throw new Error(err);
    }
};
const getByID = (id) => async (dispatch, getState) => {
    try {
        // const filters = getState().groups.filters;
        dispatch(setLoading("visa"));
        const res = await visaApi.byid(id);
        dispatch(setVisa(res.data));
    } catch (err) {
        dispatch(setLoading("visa"));
        throw new Error(err);
    }
};

const book = (form, id, attachments) => async (dispatch) => {
    try {
        dispatch(setBookLoading(true));

        const formData = new FormData();
        let existAttachments = false;

        const isValidForm = form.every((element) => {
            const today = new Date();

            // Validate ExpireDate
            const expireDate = new Date(element.ExpireDate);
            const months = Math.floor((expireDate - today) / 2592000000);
            if (months < 6) {
                toast.error("Please Enter Valid ExpireDate (more than 6 months)");
                return false;
            }

            // Validate BirthDate
            const birthDate = new Date(element.BirthDate);
            const age = Math.floor((today - birthDate) / 31557600000);
            if ((element.PassengerType === "ADT" && age < 12) ||
                (element.PassengerType === "CHD" && (age < 2 || age > 12)) ||
                (element.PassengerType === "INF" && age > 2)) {
                const ageErrorMap = {
                    "ADT": "Please Enter Valid BirthDate for Adult (12+)",
                    "CHD": "Please Enter Valid BirthDate for Child (2-12)",
                    "INF": "Please Enter Valid BirthDate for Infant (<2)"
                };
                toast.error(ageErrorMap[element.PassengerType]);
                return false;
            }

            // Validate other fields
            if (!element.DocID) {
                toast.error("Please Enter Valid Passport Number");
                return false;
            }
            if (!element.FirstName) {
                toast.error("Please Enter Valid First Name");
                return false;
            }
            if (!element.LastName) {
                toast.error("Please Enter Valid Last Name");
                return false;
            }
            if (!element.NamePrefix) {
                toast.error("Please Enter Valid Name Prefix");
                return false;
            }
            return true;
        });

        if (!isValidForm) {
            dispatch(setBookLoading(false));
            return false;
        }

        if (attachments.length === 0) {
            toast.error("Please Upload Attachments");
            dispatch(setBookLoading(false));
            return false;
        }

        for (let i = 0; i < attachments.length; i++) {
            formData.append(`files[${i}]`, attachments[i]);
        }

        // attachments.forEach(file => formData.append(`files[${i}]`, file));
        // formData.append(`files[${i}]`, attachments[i]);

        const bookInfo = {
            VISA_ID: id,
            passengers: form,
        };
        const book_res = await visaApi.book(bookInfo);

        if (!book_res.data.status) {
            throw new Error(book_res.data.description || "Booking failed");
        } 

        const res_success = book_res.data.data;
        formData.append("booking_id", res_success.id);
        formData.append("pnr", res_success.pnr);

        const attachments_res = await othersApi.attachments(formData);
        if (!attachments_res.data.status) {
            console.log(res_success);
            console.log(attachments_res.data);
            // sleep for 1 second to wait for the attachment to be uploaded
            await new Promise(resolve => setTimeout(resolve, 10000));
            throw new Error(attachments_res.data.description || "Attachment upload failed");
        }

        toast.success("Successfully Booked!");
        dispatch(setVisaErrors(""));
        dispatch(setBookLoading(false));
        window.location.href = "/profile";
        return true;
    } catch (err) {
        toast.error(err.message || "Something went wrong!");
        dispatch(setVisaErrors(err.response?.data?.message || err.message));
        dispatch(setBookLoading(false));
        throw new Error(err);
    }


    // try {
    //     dispatch(setBookLoading())
    //
    //     const formData = new FormData();
    //     let existAttachments = false;
    //
    //     // check on form if valid data
    //     form.forEach((element) => {
    //         // check the ExpireDate if valid or not and must be more than 6 months from now
    //         const expireDate = new Date(element.ExpireDate);
    //         const today = new Date();
    //         const diff = expireDate - today;
    //         const months = Math.floor(diff / 2592000000);
    //
    //         if (months < 6) {
    //             toast.error("Please Enter Valid ExpireDate (more than 6 months)");
    //             dispatch(setBookLoading())
    //             return false;
    //         }
    //
    //         // check the BirthDate if valid or not and must be more than 12 years for ADT
    //         if (element.PassengerType === "ADT") {
    //             const birthDate = new Date(element.BirthDate);
    //             const today = new Date();
    //             const diff = today - birthDate;
    //             const age = Math.floor(diff / 31557600000);
    //
    //             if (age < 12) {
    //                 toast.error("Please Enter Valid BirthDate for Adult (12+)");
    //                 dispatch(setBookLoading())
    //                 return false;
    //             }
    //         }
    //
    //
    //         // check the BirthDate if valid or not and must be more than 2 years and less than 12 years for CHD
    //
    //         if (element.PassengerType === "CHD") {
    //             const birthDate = new Date(element.BirthDate);
    //             const today = new Date();
    //             const diff = today - birthDate;
    //             const age = Math.floor(diff / 31557600000);
    //
    //             if (age < 2 || age > 12) {
    //                 toast.error("Please Enter Valid BirthDate for Child (2-12)");
    //                 dispatch(setBookLoading())
    //                 return false;
    //             }
    //         }
    //
    //         // check the BirthDate if valid or not and must be less than 2 years for INF
    //
    //         if (element.PassengerType === "INF") {
    //             const birthDate = new Date(element.BirthDate);
    //             const today = new Date();
    //             const diff = today - birthDate;
    //             const age = Math.floor(diff / 31557600000);
    //
    //             if (age > 2) {
    //                 toast.error("Please Enter Valid BirthDate for Infant (<2)");
    //                 dispatch(setBookLoading())
    //                 return false;
    //             }
    //         }
    //
    //         // check DocID if valid or not
    //         if (element.DocID === "") {
    //             toast.error("Please Enter Valid Passport Number");
    //             dispatch(setBookLoading())
    //             return false;
    //         }
    //
    //
    //         // check FirstName
    //         if (element.FirstName === "") {
    //             toast.error("Please Enter Valid First Name");
    //             dispatch(setBookLoading())
    //             return false;
    //         }
    //
    //         // check LastName
    //         if (element.LastName === "") {
    //             toast.error("Please Enter Valid Last Name");
    //             dispatch(setBookLoading())
    //             return false;
    //         }
    //
    //         // check NamePrefix
    //         if (element.NamePrefix === "") {
    //             toast.error("Please Enter Valid Name Prefix");
    //             dispatch(setBookLoading())
    //             return false;
    //         }
    //     });
    //
    //     for (let i = 0; i < attachments.length; i++) {
    //         existAttachments = true;
    //         formData.append(`files`, attachments[i]);
    //     }
    //
    //     if (!existAttachments) {
    //         toast.error("Please Upload Attachments");
    //         dispatch(setBookLoading())
    //         return false;
    //     }
    //
    //     const bookInfo = {
    //         VISA_ID: id,
    //         passengers: form,
    //     };
    //     const book_res = await visaApi.book(bookInfo);
    //
    //     if (book_res.data.status) {
    //         const res_success = book_res.data.data;
    //
    //         formData.append("booking_id", res_success.id);
    //         formData.append("pnr", res_success.pnr);
    //
    //         const attachments_res = await othersApi.attachments(formData);
    //         if (attachments_res.data.status) {
    //             toast.success("Successfully Booked!");
    //             dispatch(setVisaErrors(""));
    //             dispatch(setBookLoading())
    //             window.location.href = "/profile";
    //             return true;
    //         } else {
    //             toast.error("Something went wrong uploading attachments !");
    //             dispatch(setVisaErrors(attachments_res.data.description));
    //             dispatch(setBookLoading())
    //         }
    //     } else {
    //         toast.error("Something went wrong !");
    //         dispatch(setVisaErrors(book_res.data.description));
    //         dispatch(setBookLoading())
    //     }
    // } catch (err) {
    //     dispatch(setVisaErrors(err.response.data.message));
    //     toast.error("Something went wrong !");
    //     dispatch(setBookLoading())
    //     throw new Error(err);
    // }
};

const getCountry = () => async (dispatch) => {
    try {
        dispatch(setLoading("country"));
        const res = await visaApi.getCountry();
        dispatch(getCountries(res.data));
    } catch (err) {
        dispatch(setLoading("country"));
        throw new Error(err);
    }
};

export const visa = {
    getData,
    book,
    getByID,
    getCountry,
};
